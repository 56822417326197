<template>
    <div class="nuestro-compromiso">
        <div class="row">
            <div class="col-sm-7" style="text-align: justify;">
                <h2 style="color: #2c3e50; font-weight: 700; margin-bottom: 1em;">{{ $t('message.nuestro_compromiso') }}</h2>
                <h7 style="color: #004E7D; font-weight: 500;">{{ $t('message.nuestro_compromiso_description') }}</h7>
            </div>
            <div class="col-sm-5 d-flex align-items-center" style="justify-content: right; margin-top: 1.8em;">
                <img src="../assets/nuestro-compromiso-2.png" alt="" style="width: 25%; height: 60%; margin-right: 3em;">
                <img src="../assets/nuestro-compromiso-1.png" alt="" style="width: 50%; height: 90%;">
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.nuestro-compromiso {
    background-color: #EBF1F5;
    padding: 5em;
}

  </style>
  