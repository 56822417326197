// en.js
export default {
    message: {
      nuestros_servicios: 'Our Services',
      quienes_somos: 'Who we are?',
      contactanos: "Contact us!",
      somos_tecnologia: 'We are Technology at the service of logistics!',
      nuestras_soluciones: 'Our solutions',
      soluciones_adicionales: 'Additional Solutions',
      nuestro_compromiso: 'Our Commitment',
      aliados: 'Strategic Allies',
      que_nos_diferencia: 'What differentiates us?',
      nuestra_historia: 'Our History',
      nuestro_enfoque: 'Our Approach',
      nuestros_principios: 'Our Principles',
      politicas: 'Privacy Policy -  ',
      terminos: 'Terms and Conditions',
      tecnologia: 'Technology',
      aereo: 'Air',
      maritimo: 'Maritime',
      terrestre: 'Land',
      porth_app: 'Porth.app:',
      porth_description: 'It is one of our strategic technology investments for real-time visualization of shipments.',
      tive: 'Tive:',
      tive_description: 'Real-time tracker that measures temperature, lighting, shock, humidity and location.',
      aurora: 'Aurora: ',
      aurora_description: 'RPA management and online corer',
      aurelio: 'Aurelio: ',
      aurelio_description: 'Virtual assistant and shipment management.',
      linc: 'Linc 3.0: ',
      linc_description: 'Digital logistics platform. Global online quotation engine.',
      servicio_puerta_puerta: 'Door to door services to and from anywhere in the world.',
      chatear_parciales: 'Partial and full charter',
      reservacion_espacios: 'Space booking with airlines.',
      recepcion_manejo: 'Reception and handling of cargo at the airport.',
      preparacion_tramite: 'Preparation and processing of documents.',
      recolecta: 'Pick up anywhere in the world.',
      importacion_exportacion: 'Import and export.',
      seguro_carga: 'Full Cover and 0% Deductible cargo insurance.',
      coordinacion_equipos: 'Coordination of complete teams to and from anywhere in the world.',
      consolidado_semanal: 'Weekly consolidation from Miami, FL to Central America and Panama.',
      carga_sobredimensionada: 'Oversized cargo and special projects.',
      servicio_puerta: 'Door to door services.',
      red_transporte: 'Central America - Mexico transport network.',
      coordinacion_furgones: 'Complete van coordination to and from Central America, Panama and Mexico.',
      consolidado_semanal_terrestre: 'Consolidated Weekly',
      bodegas_consolidacion: 'Consolidation warehouses in Mexico, Panama and Costa Rica.',
      recolectas_terrestre: 'Pick up anywhere in the Central American Region and Mexico.',
      contiza_embarques: 'Quote your shipments',
      cotiza_cargas: 'Quote your cargo',
      cotiza1: 'Quote your shipments ',
      cotiza2: 'Air ',
      cotiza3: ' and ',
      cotiza4: ' Ocean ',
      cotiza5: 'online',
      des1: 'Transparency of rates',
      des2: 'Ease of booking',
      des3: 'Instant rates',
      des4: 'Personal account manager',
      des5: 'Collaborative platform',
      des6: 'Real-time visibility',
      des7: 'No need to register.',
      des8: 'Instant online quotation.',
      des9: 'FTL and LTL transportation types.',
      des10: 'You can insure your cargoes.',
      des11: 'Transparent pricing.',
      cotiza1_terrestre: 'Quote your freight',
      terrestres: 'Land',
      seguro_de_carga: 'Cargo Insurance',
      cargas_especiales: 'Special Loads',
      servicio_custodio: 'Custodian Service',
      tramite_aduanal: 'Customs procedure',
      habla_con_asesor: 'Talk to an advisor',
      nuestro_compromiso_description: 'Grupo Linc is committed to continuous improvement in the performance of our Management System, with the purpose of satisfying the requirements of our customers who are looking for a local and international transportation service. We want to be a timely, reliable and safe partner during the time the merchandise is under our responsibility.',
      nos_hace_especiales: 'What makes us special?',
      somos_regionales: 'We are Regional in Central America with Global reach',
      servicio_en_tecnologia: 'Services with Real-Time Tracking and Management Technology',
      tranparencia_tarifas: 'Transparency of rates with our Online Quoter',
      seguridad_calidad: 'Security and Quality backed by Certifications',
      especial_description: 'Talk to our professional logistics experts, access a customized solution for your company and experience a live demo of our solution.',
      queremos_tranformar: 'We want to digitally transform the International Transport Industry generating employment and welfare!',
      grupo_linc: 'Grupo Linc',
      quienes_somos_description1: 'We are a group of more than 40 collaborators focused on international air and ocean transportation services, we have also developed our own Central American road transportation network from Mexico to Panama and from Zona Libre and San Jose to Central America.',
      quienes_somos_description2: 'Grupo Linc, has a strong team of professionals focused on designing solutions for our customers.',
      quienes_somos_description3: 'We also handle oversized/overweight cargo, dangerous goods, cross trade, project cargo, in addition, we have developed our trucking network from Costa Rica to cities in Central America and Panama.',
      quienes_somos_description4: 'Linc Costa Rica, has had a Safety and Control Management System since 2016 and was certified in 2017 and a Quality Management System certified in 2019.',
      trayectoria: '+11 years of trajectory and evolution in the logistics industry',
      mision: 'Mission',
      vision: 'Visión',
      mision_description1: '“At Grupo Linc, people are the most important thing. We specialize in logistics and international transportation, providing customized, technological and efficient solutions. ',
      mision_description2: 'Our approach is based on information transparency, quality and safety, guaranteeing services of excellence for our customers."',
      vision_description: '"To be a profitable company, leader in logistics and international transportation, with multi-Latin presence and global reach, guaranteeing dynamic solutions to the changing market, through the strategic implementation of technology, human warmth, continuous improvement and safe commerce"',
      fe: 'Faith in God',
      description_fe: 'Certainty of what is hoped for, the conviction of what is not seen.',
      pasion: 'Passion',
      description_pasion: 'Love and dedication for what we do',
      honestidad: 'Honesty',
      description_honestidad: 'Truthfulness of the word.',
      integridad: 'Integrity',
      description_integridad: 'Acting in accordance with ethics and morals',
      compromiso: 'Commitment',
      description_compromiso: 'To comply with what has been agreed. ',
      liderazgo: 'Leadership',
      description_liderazgo: 'To empower the soft and hard skills of our collaborators, teaching, accompanying and trusting.',
      podemos_ayudarte: 'How can we help you?',
      podemos_ayudarte_description: 'Whether you have a question about solutions, scope, rates, or any inquiry, our team is ready to answer all your questions.',
      description_2017: 'We open offices in Guatemala. We joined the WCA World agent network. And we are BASC certified.',
      description_2012: 'We started operations in Costa Rica', 
      description_2013: 'Offices are established in Nicaragua and Panama (City and Free Zone).', 
      description_2019: 'We join Twig Network.', 
      description_2020: 'We are ISO:9001 certified. Start of alliance with Parnity.', 
      description_2021: 'Launched Porth.app with Gustavo Daniel', 
      description_2022: 'Launched aureliobot Director of Virtual Princing', 
      description_2023: 'We created auroraboot the Terrestrial Quotation System. We launched Linc 3.0. And we joined Conqueror.', 
      nombre: 'Complete Name',
      correo: 'Mail',
      pais: 'Country',
      telefono: 'Phone',
      empresa: 'Company',
      servicio: 'Service',
      solicitud: 'Request',
      ayuda: 'Help',
      blog: 'Blog',
      transporte_terrestre: 'Land Transport',
      transporte_maritimo: 'Maritime transport',
      transporte_aereo: 'Air transport',
      torre_control: 'Shipment Control Tower',
      enviar: 'Send'
    },
  };
  