<template>
    <div class="aliados-estrategicos">
        <div class="row">
            <h2 style="color: #004E7D; font-weight: 700;">{{ $t('message.aliados') }}</h2>
            <div class="container text-center my-3">
		<div class="row mx-auto my-auto justify-content-center mt-4">
			<div id="recipeCarousel" class="carousel slide" data-bs-ride="carousel">
				<div class="carousel-inner" role="listbox">
					<div class="carousel-item active" style="width: 85%; margin-left: 5%; align-items: center;">
						<div class="col-md-4">
							<div class="card">
								<div class="card-img">
                  <img src="../assets/socio4.svg" class="d-block" style="width: 75%;" alt="...">
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item" style="width: 85%; margin-left: 5%; align-items: center;">
						<div class="col-md-4">
							<div class="card">
								<div class="card-img">
                    <img src="../assets/socio2.svg" class="d-block" style="width: 75%;" alt="...">
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item" style="width: 85%; margin-left: 5%; align-items: center;">
						<div class="col-md-4">
							<div class="card">
								<div class="card-img">
                    <img src="../assets/socio3.svg" class="d-block" style="width: 75%;" alt="...">
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item" style="width: 85%; margin-left: 5%; align-items: center;">
						<div class="col-md-4">
							<div class="card">
								<div class="card-img">
                  <img src="../assets/socio1.svg" class="d-block" style="width: 35%;" alt="...">
                                    
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item" style="width: 85%; margin-left: 5%; align-items: center;">
						<div class="col-md-4">
							<div class="card">
								<div class="card-img">
                      <img src="../assets/socio5.svg" class="d-block" style="width: 50%;" alt="...">
								</div>
							</div>
						</div>
					</div>
				</div>
                <button class="carousel-control-prev" href="#recipeCarousel"  type="button" data-bs-slide="prev">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" style="color: white; background: #e3e3e3; font-size: 3em; padding: 10px 5px; border-radius: 25px; width: 1em"/>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" href="#recipeCarousel"  type="button" data-bs-slide="next">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" style="color: white; background: #e3e3e3; font-size: 3em; padding: 10px 5px; border-radius: 25px; width: 1em"/>
                    <span class="visually-hidden" aria-hidden="true">Previous</span>
                </button>
			</div>
		</div>		
	</div>
        
        </div>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
  },
  mounted() {
    this.initializeCarousel()
  },
  methods: {
    initializeCarousel() {
      let items = this.$el.querySelectorAll('.carousel .carousel-item')

      items.forEach((el) => {
        const minPerSlide = 3
        let next = el.nextElementSibling
        for (var i = 1; i < minPerSlide; i++) {
          if (!next) {
            // wrap carousel by using first child
            next = items[0]
          }
          let cloneChild = next.cloneNode(true)
          el.appendChild(cloneChild.children[0])
          next = next.nextElementSibling
        }
      })
    }
  }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.aliados-estrategicos {
    padding: 5em;
}

@media (max-width: 767px) {
		.carousel-inner .carousel-item > div {
			display: none;
		}
		.carousel-inner .carousel-item > div:first-child {
			display: block;
		}
	}
    .carousel-inner {
        width: 80%;
        margin-left: 1.5em;
    }

    .aliados-estrategicos {
      padding: 3em;
  }
	.carousel-inner .carousel-item.active,
	.carousel-inner .carousel-item-next,
	.carousel-inner .carousel-item-prev {
		display: flex;
	}

	@media (min-width: 768px) {

  .carousel-inner {
    width: 80%;
    margin-left: 8em;
  }
		.carousel-inner .carousel-item-end.active,
		.carousel-inner .carousel-item-next {
			transform: translateX(25%);
		}

		.carousel-inner .carousel-item-start.active, 
		.carousel-inner .carousel-item-prev {
			transform: translateX(-25%);
		}
	}

	.carousel-inner .carousel-item-end,
	.carousel-inner .carousel-item-start { 
		transform: translateX(0);
	}

    .card {
        border: 0;
    }

    .card-img {
        text-align: -webkit-center;
    }
  </style>
  