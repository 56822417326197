<template>
    <ImagenEquipo></ImagenEquipo>
    <QuienesSomosDescription id="quienes-somos-section"></QuienesSomosDescription>
    <NuestraHistoria id="nuestra-historia-section"></NuestraHistoria>
    <NuestroEnfoque id="nuestro-enfoque-section"></NuestroEnfoque>
    <NuestrosPrincipios id="nuestros-principios-section"></NuestrosPrincipios>
    <ContactanosHistoria></ContactanosHistoria>
  </template>

  <script>
    import ImagenEquipo from '@/components/ImagenEquipo.vue'
    import QuienesSomosDescription from '@/components/QuienesSomosDescription.vue'
    import NuestraHistoria from '@/components/NuestraHistoria.vue'
    import NuestroEnfoque from '@/components/NuestroEnfoque.vue'
    import NuestrosPrincipios from '@/components/NuestrosPrincipios.vue'
    import ContactanosHistoria from '@/components/ContactanosHistoria.vue'





  export default {
    name: 'App',
    components: {
    ImagenEquipo,
    QuienesSomosDescription,
    NuestraHistoria,
    NuestroEnfoque,
    NuestrosPrincipios,
    ContactanosHistoria
}
  }
  </script>
  
  <style>
  </style>
  