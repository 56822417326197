<template>
    <div class="top-bar">
        <nav class="navbar navbar-expand-lg navbar-light  fixed-top">
        <div class="container-fluid">
            <a class="navbar-brand" href="#" style="margin-left: 3em;">
                <img src="../assets/logo-linc.png" alt="" width="90" height="84">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <router-link  class="nav-link-item"  :to="{ name: 'NuestrosServicios' }"  @click="goUp()">{{ $t('message.nuestros_servicios') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link  class="nav-link-item"  :to="{ name: 'QuienesSomos' }"  @click="goUp()">{{ $t('message.quienes_somos') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link  class="nav-link-item" :to="{ name: 'ContactanosLinc' }">{{ $t('message.contactanos') }}</router-link>
                </li>
            </ul>
            <div class="dropdown" style="margin-left: auto;">
                <button class="btn-phone" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    <font-awesome-icon :icon="['fas', 'phone-alt']" size="lg" style="color: #0E4989"/><br>                  
                </button>
                <ul class="dropdown-menu" style="background: #E6EDF2; border: 0; border-radius: 1em;  box-shadow: 0px 4px 6px rgba(0.5, 0.5, 0.5, 0.5);" aria-labelledby="dropdownMenu2">
                    <li><button class="dropdown-item" type="button">
                        <img src="../assets/guatemala.png" alt=""  style="width: 2em; height: 2em;">
                        <a style="text-decoration: auto;" href="tel:+50222938953"><h7 class="text-center" style="color: #0E4989">+(502) 2293 8953</h7></a>
                    </button></li>
                    <li><button class="dropdown-item" type="button">
                        <img src="../assets/nicaragua.png" alt=""  style="width: 2em; height: 2em;">
                        <a style="text-decoration: auto;" href="tel:+50522683105"><h7 class="text-center" style="color: #0E4989">+(505) 2268 3105</h7></a>
                    </button></li>
                    <li><button class="dropdown-item" type="button">
                        <img src="../assets/costarica.png" alt=""  style="width: 2em; height: 2em;">
                        <a style="text-decoration: auto;" href="tel:+50640012290"><h7 class="text-center" style="color: #0E4989">+(506) 4001 2290</h7></a>
                    </button></li>
                    <li><button class="dropdown-item" type="button">
                        <img src="../assets/panama.png" alt="" style="width: 2em; height: 2em;">
                        <a style="text-decoration: auto;" href="tel:+50769483832"><h7 class="text-center" style="color: #0E4989">+(507) 6948-3832</h7></a>
                    </button></li>
                </ul>
            </div>
            <select class="form-select-language" @input="changeLanguage($event.target.value)">
                <option selected value="es">ESP</option>
                <option value="en">ENG</option>
            </select>
            </div>
        </div>
        </nav>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  },
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale
    },
    goUp() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }
  }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.top-bar{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.navbar {
    height: 6em;
    background-color: white;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.navbar-collapse.show {
    background-color: white;
}
.form-select-language {
    margin-left: 2em;
    margin-right: 4em;
    border: 0;
}
.nav-link-item {
    color: #101950;
    font-weight: 700;
    font-size: large;
    text-decoration: auto;
    margin: 1.5em;
}
.navbar-collapse .show {
    background: white;
    border: 1px solid blue;
}
.btn-phone {
    background: white;
    border: 0;
}
.btn-phone:focus{
    border: 0;
}
.dropdown-item:hover{
    background: rgb(168, 172, 207);
}
  </style>
  