<template>
    <CarruselInfo></CarruselInfo>
    <NosDiferencia id="nos-diferencia-section"></NosDiferencia>
    <NuestrasSoluciones id="nuestra-soluciones-section"></NuestrasSoluciones>
    <SolucionesAdicionales id="soluciones-adicionales-section"></SolucionesAdicionales>
    <NuestroCompromiso id="nuestro-compromiso-section"></NuestroCompromiso>
    <AliadosEstrategicos id="aliados-estrategicos-section"></AliadosEstrategicos>
    <ContactanosServicios></ContactanosServicios>
  </template>
  
  <script>
  import CarruselInfo from './../components/CarruselInfo.vue'
  import NuestrasSoluciones from './../components/NuestrasSoluciones.vue'
  import SolucionesAdicionales from './../components/SolucionesAdicionales.vue'
  import NuestroCompromiso from './../components/NuestroCompromiso.vue'
  import AliadosEstrategicos from './../components/AliadosEstrategicos.vue'
  import NosDiferencia from './../components/NosDiferencia.vue'
  import ContactanosServicios from '@/components/ContactanosServicios.vue'
  
  export default {
    components: {
        CarruselInfo, NuestrasSoluciones, SolucionesAdicionales, NuestroCompromiso, AliadosEstrategicos, NosDiferencia,
        ContactanosServicios
    }
  }
  </script>
  
  <style>
  </style>
  