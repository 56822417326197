<template>
    <div class="carrusel-info">
        <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active" data-bs-interval="10000">
                    <div class="content-info-image-1 d-block w-100">
                        <div class="container">
                            <div class="row" style="height: 85vh; align-items: center;">
                                <div class="col" style="margin-left: 5%;">
                                    <div>
                                        <h1 class="title-cotiza" style="color: #172C49;">{{ $t('message.cotiza1') }}</h1>
                                        <div class="d-flex gap-3">
                                            <h1  class="title-cotiza" style="color: #004E7D;">{{ $t('message.cotiza2') }}</h1>
                                            <h1  class="title-cotiza" style="color: #172C49;">{{ $t('message.cotiza3') }}</h1>
                                            <h1  class="title-cotiza"  style="color: #026666;">{{ $t('message.cotiza4') }}</h1>
                                        </div>
                                        <h1  class="title-cotiza" style="color: #172C49;">{{ $t('message.cotiza5') }}</h1>
                                    </div>
                                    <div class="mt-4 d-grid">
                                        <h5 class="items-cotiza">{{ $t('message.des1') }}</h5>
                                        <h5 class="items-cotiza">{{ $t('message.des2') }}</h5>
                                        <h5 class="items-cotiza">{{ $t('message.des3') }}</h5>
                                        <h5 class="items-cotiza">{{ $t('message.des4') }}</h5>
                                        <h5 class="items-cotiza">{{ $t('message.des5') }}</h5>
                                        <h5 class="items-cotiza" style="font-weight: 700;">{{ $t('message.des6') }}</h5>
                                    </div>
                                    <router-link  class="btn btn-bottom" type="button" :to="{ name: 'ContactanosIcontainers' }">{{ $t('message.cotiza1') }}</router-link>
                                </div>
                                <div class="col" style="text-align: center;">
                                    <div class="image-white"><img src="../assets/aereos-maritimos.png" style=" width: 23em;" alt="..."></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-bs-interval="2000">
                    <div class="content-info-image-2 d-block w-100">
                        <div class="container">
                            <div class="row" style="height: 85vh; align-items: center;">
                                <div class="col" style="margin-left: 5%;">
                                    <div>
                                        <h1 class="title-cotiza" style="color: #172C49;">{{ $t('message.cotiza1_terrestre') }}</h1>
                                        <div class="d-flex gap-3">
                                            <h1 class="title-cotiza" style="color: #E52037;">{{ $t('message.terrestres') }}</h1>
                                            <h1 class="title-cotiza" style="color: #172C49;">{{ $t('message.cotiza5') }}</h1>
                                        </div>
                                    </div>
                                    <div class="mt-4 d-grid">
                                        <h5 class="items-cotiza">{{ $t('message.des7') }}</h5>
                                        <h5 class="items-cotiza">{{ $t('message.des8') }}</h5>
                                        <h5 class="items-cotiza">{{ $t('message.des9') }}</h5>
                                        <h5 class="items-cotiza">{{ $t('message.des10') }}</h5>
                                        <h5 class="items-cotiza" style="font-weight: 700;">{{ $t('message.des11') }}</h5>
                                    </div>
                                        <a class="btn btn-bottom" type="button"  href="https://aurorabot.linc-ca.com/" target="_blank">{{ $t('message.cotiza_cargas') }}</a>
                                </div>
                                <div class="col" style="text-align: center;">
                                    <div class="image-white"><img src="../assets/terrestre.png" style=" width: 23em;" alt="..."></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                <font-awesome-icon :icon="['fas', 'chevron-left']" style="color: #e3e3e3; background: white; font-size: 3em; padding: 10px 5px; border-radius: 25px; width: 1em"/>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                <font-awesome-icon :icon="['fas', 'chevron-right']" style="color: #e3e3e3; background: white; font-size: 3em; padding: 10px 5px; border-radius: 25px; width: 1em"/>
                <span class="visually-hidden">Next</span>
            </button>
            </div>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .content-info-image-1 {
    height: 85vh;
    background-color: #EBF1F5;
    background-image: url('../assets/background_image.png');
    background-size: cover;
  }
  .content-info-image-2 {
    height: 85vh;
    background-color: #EBF1F5;
    background-image: url('../assets/background_image2.png');
    background-size: cover;
  }
  .content-image-info {
    background-color: white;
    border-radius: 5em;
    padding: 2em;
    display: inline-flex;
  }
  .btn-bottom {
    border-radius: 2em;
    background-color: #004E7D;
    color: white;
    margin-top: 10%;
    width: 60%;
    height: 10%;
    font-size: x-large;
  }
  .btn-bottom:hover {
    background-color: #A6C2E1;
  }
  .title-cotiza {
    font-size: calc(1.375rem + 2vw);
    font-weight: 700;
  }
  .items-cotiza {
    margin-bottom: 0;
    color:#004E7D;
  }

  @media (max-width: 768px) {
  .content-info-image-1,
  .content-info-image-2 {
    height: auto;
    background-size: cover;
  }

  .carousel-inner {
    height: auto;
  }

  .carousel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .col {
    margin: 0;
    text-align: center;
  }

  .btn-bottom {
    width: 80%;
    height: auto;
    font-size: large;
    margin-top: 2em;
  }

  .title-cotiza {
    font-size: 1.5rem;
  }

  .image-white {
    display: none;
  }
}

  </style>
  