<template>
  <TopBar></TopBar>
  <div class="my-page">
    <router-view></router-view>
  </div>
  <FooterBar></FooterBar>
</template>

<script>
import TopBar from './components/TopBar.vue'
import FooterBar from './components/FooterBar.vue'



export default {
  name: 'App',
  components: {
    TopBar,FooterBar
  }
}
</script>

<style>
#app {
  color: #2c3e50;
} 
.my-page {
  margin-top: 6em;
}
</style>
