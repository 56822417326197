<template>
    <div class="imagen-equipo">
        <h2 class="text-title">{{ $t('message.queremos_tranformar') }}</h2>
        <img style="margin-top: 2em;" src="../assets/foto-grupo-linc.png" class="img-grupo-linc" alt="">

    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.imagen-equipo {
    padding: 2em;
    text-align: center;
    text-align: -webkit-center;
}
.img-grupo-linc {
    width: 75vh;
    border-radius: 5em;
}

.text-title {
  color: #004E7D;
  width: 50%;
  font-weight: 700;
}

@media (max-width: 768px) {
  .imagen-equipo {
    padding: 2em;
    text-align: center;
    text-align: -webkit-center;
}
.text-title{
  width: 100%;
}
.img-grupo-linc {
  width: 45vh;
  border-radius: 3em;
}
}
  </style>
  