<template>
    <div class="soluciones-adicionales">
      <div class="d-flex w-full" style="justify-content: space-between;">
        <h2 style="color: white; font-weight: 700;">{{ $t('message.soluciones_adicionales') }}</h2>
        <div>
          <a href="https://wa.me/+50670519694" target="_blank" class="btn btn-asesor px-4" type="button">{{ $t('message.habla_con_asesor') }}</a>
        </div>
      </div>
      <div class="row my-5">
        <div class="col text-center">
          <img src="../assets/icon-block.png" class="icon-soluciones" alt="...">
          <h5 style="color: white;">{{ $t('message.seguro_de_carga') }}</h5>
        </div>
        <div class="col text-center">
          <img src="../assets/warning-icon.png" class="icon-soluciones" style="width: 28%;" alt="...">
          <h5 style="color: white;">{{ $t('message.cargas_especiales') }}</h5>
        </div>
        <div class="col text-center">
          <img src="../assets/icon-custodio.png" class="icon-soluciones" alt="...">
          <h5 style="color: white;">{{ $t('message.servicio_custodio') }}</h5>
        </div>
        <div class="col text-center">
          <img src="../assets/icon-tramite.png" class="icon-soluciones" alt="...">
          <h5 style="color: white;">{{ $t('message.tramite_aduanal') }}</h5>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n';
  
  export default {
    setup() {
      const { t } = useI18n();
      return {
        t
      };
    }
  };
  </script>
  
  <style scoped>
  .soluciones-adicionales {
    background-color: #004E7D;
    padding: 3em 5em;
  }
  
  .icon-soluciones {
    width: 20%;
    margin-bottom: 1em;
  }
  
  .btn-asesor {
    background-color: #EBF1F5;
    color: #172C49;
    border-radius: 5em;
    font-weight: 600;
    font-size: x-large;
    padding: 0.5em 1em;
    display: inline-block;
  }
  
  .btn-asesor:hover {
    background-color: #A6C2E1;
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .soluciones-adicionales {
      padding: 3em 1em;
    }
  
    .icon-soluciones {
      width: 50%;
      margin-bottom: 0.5em;
    }
  
    .btn-asesor {
      font-size: medium;
    }
  }
  </style>
  