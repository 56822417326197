<template>
   <div class="contactanos-linc">
      <div class="row row-cols-2">
        <div class="col-md-6 col-12 my-content" style="align-self: center;">
          <h3 style="color: #004E7D; margin-bottom: 0.5em; text-align: center;">{{ $t('message.contactanos') }}</h3>
          <h5 style="color: #004E7D; margin-bottom: 1.5em; margin-left: 1.1em;">{{ $t('message.podemos_ayudarte_description') }}</h5>
          <div class="row row-cols-2 row-cols-lg-2 g-2 g-lg-3" style="justify-content: center;">
              <div class="col-md-6 col-12 card-info" style="width: 43%;">
                <div class="px-4 py-2 border content-items" style="background: #EBF1F5; border-radius: 1em;">
                  <div class="d-flex" style="justify-content: center;">
                    <img src="../assets/guatemala.png" alt=""  style="width: 2.5em; height: 2.5em;">
                  </div>
                  <ul style="text-align: center; padding: 0;">
                      <a style="text-decoration: auto;" href="tel:+50222938953"><h7 class="text-center" style="color: #0E4989">Tel: +(502) 2293 8953</h7></a><br>
                      <a style="text-decoration: auto;" href="tel:+50222786731"><h7 class="text-center" style="color: #0E4989">+(502) 2278 6731</h7></a><br>
                      <a style="text-decoration: auto;" href="mailto:sales@linc-ca.com"><h7 class="text-center" style="color: #0E4989">sales@linc-ca.com</h7></a>
                    </ul>
                </div>
              </div>
              <div class="col-md-6 col-12" style="width: 43%;">
                <div class="px-4 py-2 border content-items-contact"  style="background: #EBF1F5; border-radius: 1em;">
                  <div class="d-flex" style="justify-content: center;">
                    <img src="../assets/nicaragua.png" alt=""  style="width: 2.5em; height: 2.5em;">
                  </div>
                  <ul style="text-align: center; padding: 0;">
                    <a style="text-decoration: auto;" href="tel:+50522683105">
                      <h7 class="text-center" style="color: #0E4989">Tel: +(505) 2268 3105</h7>
                    </a>
                    <br>
                    <h7 class="text-center" style="color: #0E4989">Ext: 104/106</h7>
                    <br>
                    <a style="text-decoration: auto;" href="mailto:sales@linc-ca.com">
                      <h7 class="text-center" style="color: #0E4989">sales@linc-ca.com</h7>
                    </a>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-12" style="width: 43%;">
                <div class=" px-4 py-2 border content-items-contact"  style="background: #EBF1F5; border-radius: 1em;">
                  <div class="d-flex" style="justify-content: center;">
                    <img src="../assets/costarica.png" alt=""  style="width: 2.5em; height: 2.5em;">
                  </div>
                  <ul style="text-align: center; padding: 0;">
                    <a style="text-decoration: auto;" href="tel:+50640012290">
                      <h7 class="text-center" style="color: #0E4989"> Tel: +(506) 4001 2290</h7>
                    </a>
                    <br>
                    <a style="text-decoration: auto;" href="mailto:sales@linc-ca.com">
                      <h7 class="text-center" style="color: #0E4989">sales@linc-ca.com</h7>
                    </a>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-12" style="width: 43%;">
                <div class="px-4 py-2 border content-items-contact"  style="background: #EBF1F5; border-radius: 1em;">
                  <div class="d-flex" style="justify-content: center;">
                    <img src="../assets/panama.png" alt="" style="width: 2.5em; height: 2.5em;">
                  </div>
                  <ul style="text-align: center; padding: 0;">
                      <a style="text-decoration: auto;" href="tel:+50769483832">
                        <h7 class="text-center" style="color: #0E4989">Tel: +(507) 6948-3832</h7>
                      </a>
                      <br>
                      <a style="text-decoration: auto;" href="mailto:sales@linc-ca.com">
                        <h7 class="text-center" style="color: #0E4989">sales@linc-ca.com</h7>
                      </a>
                  </ul>
                </div>
              </div>
          </div>
          <div class="col" style="margin-top: 1.5em; text-align: center;">
            <a type="button" class="mx-1" href="https://www.facebook.com/conexionlinc" target="_blank">
                <font-awesome-icon style="color: #172C49; width: 2.5em; height: 1.5em;" class="icon-social" :icon="['fab', 'facebook']" size="lg"/>
            </a>
            <a type="button" class="mx-1" href="https://www.instagram.com/lincgrupo/" target="_blank">
                <font-awesome-icon style="color: #172C49; width: 2.5em; height: 1.5em;" class="icon-social" :icon="['fab', 'instagram']" size="lg"/>
            </a>
            <a type="button" class="mx-1" href="https://www.linkedin.com/company/grupo-linc" target="_blank">
                <font-awesome-icon style="color: #172C49; width: 2.5em; height: 1.5em;" class="icon-social" :icon="['fab', 'linkedin']" size="lg"/>
            </a>
          </div>
        </div>
        <div class="col-md-6 col-12" style="text-align: center;">
          <form class="row g-3 form-contactanos" style="margin-top: 1em;" v-on:submit.prevent="contactos">
            <div class="alert alert-warning" role="alert" v-if="this.error">
              <ul style="list-style: none;">
                <li v-for="(item, index) in this.error_msg" v-bind:key="index">
                  {{ item[0] }}
                </li>
              </ul>
            </div>
            <div class="alert alert-success" role="alert" v-if="this.success">
              Gracias, por enviar su informacion.
            </div>
            <div class="col-12" style="text-align: left;">
              <label for="name" class="form-label" style="color: #004E7D;">{{ $t('message.nombre') }}</label>
              <input type="text" class="form-control" style="border-radius: 5em;" id="name" v-model="name">
            </div>
            <div class="col-12" style="text-align: left;">
              <label for="email" class="form-label" style="color: #004E7D;">{{ $t('message.correo') }}</label>
              <input type="text" class="form-control" style="border-radius: 5em;" id="email" v-model="email">
            </div>
            <div class="col-md-5" style="text-align: left;">
              <label for="country" class="form-label" style="color: #004E7D;">{{ $t('message.pais') }}</label>
              <select style="border-radius: 5em;" class="form-select" v-model="country">
                <option></option>
                <option v-for="(country, index) in countriesWithFlags" :key="index" :value="country.code + '-' +country.label">
                  {{ '(+' + country.code + ')' }}
                  {{ country.label }}
                </option>
              </select>
            </div>
            <div class="col-md-7" style="text-align: left;">
              <label for="phone" class="form-label" style="color: #004E7D;">{{ $t('message.telefono') }}</label>
              <input type="text" class="form-control" style="border-radius: 5em;" id="phone" v-model="phone">
            </div>

            <div class="col-md-6" style="text-align: left;">
              <label for="company" class="form-label" style="color: #004E7D;">{{ $t('message.empresa') }}</label>
              <input type="text" class="form-control" style="border-radius: 5em;" id="company" v-model="company">
            </div>
            <div class="col-md-6" style="text-align: left;">
              <label for="service" class="form-label" style="color: #004E7D;">{{ $t('message.servicio') }}</label>
              <select id="service" class="form-select" style="border-radius: 5em;" v-model="service">
                <option selected></option>
                <option>{{ $t('message.transporte_terrestre') }}</option>
                <option>{{ $t('message.transporte_maritimo') }}</option>
                <option>{{ $t('message.transporte_aereo') }}</option>
                <option>{{ $t('message.seguro_de_carga') }}</option>
                <option>{{ $t('message.torre_control') }}</option>
              </select>
            </div>
            <div class="col-md-12" style="text-align: left;">
              <label for="request" class="form-label" style="color: #004E7D;">{{ $t('message.solicitud') }}</label>
              <textarea class="form-control" style="border-radius: 1em;" id="request" rows="3" v-model="request"></textarea>
            </div>
            <div class="col-6" style="text-align: left;">
              <button type="submit" class="btn-send">{{ $t('message.enviar') }}</button>
            </div>
          </form>
        </div>
      </div>
   </div>

  </template>

<script>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'



export default {
    data: function (){
      return {
        name: "",
        email: "",
        country: "",
        phone: "",
        company: "",
        service: "",
        request: "",
        error : false,
        error_msg: null,
        success: false

      }

    },
    setup() {
      const { t } = useI18n()
      const countries = ref(
        [
          { value: 'MX', code: '52', label: 'Mexico' },
          { value: 'US', code: '1', label: 'United States' },
          { value: 'CN', code: '86', label: 'China' },
          { value: 'IT', code: '39', label: 'Italy' },
          { value: 'CA', code: '1', label: 'Canada' },
          { value: 'AF', code: '93', label: 'Afghanistan' },
          { value: 'AL', code: '355', label: 'Albania' },
          { value: 'DZ', code: '213', label: 'Algeria' },
          { value: 'AS', code: '1', label: 'American Samoa' },
          { value: 'AD', code: '376', label: 'Andorra' },
          { value: 'AO', code: '244', label: 'Angola' },
          { value: 'AI', code: '1', label: 'Anguilla' },
          { value: 'AQ', code: '', label: 'Antarctica' },
          { value: 'AG', code: '1', label: 'Antigua and/or Barbuda' },
          { value: 'AR', code: '54', label: 'Argentina' },
          { value: 'AM', code: '374', label: 'Armenia' },
          { value: 'AW', code: '297', label: 'Aruba' },
          { value: 'AU', code: '61', label: 'Australia' },
          { value: 'AT', code: '43', label: 'Austria' },
          { value: 'AZ', code: '994', label: 'Azerbaijan' },
          { value: 'BS', code: '1', label: 'Bahamas' },
          { value: 'BH', code: '973', label: 'Bahrain' },
          { value: 'BD', code: '880', label: 'Bangladesh' },
          { value: 'BB', code: '1', label: 'Barbados' },
          { value: 'BY', code: '375', label: 'Belarus' },
          { value: 'BE', code: '32', label: 'Belgium' },
          { value: 'BZ', code: '501', label: 'Belize' },
          { value: 'BJ', code: '229', label: 'Benin' },
          { value: 'BM', code: '1', label: 'Bermuda' },
          { value: 'BT', code: '975', label: 'Bhutan' },
          { value: 'BO', code: '591', label: 'Bolivia' },
          { value: 'BA', code: '387', label: 'Bosnia and Herzegovina' },
          { value: 'BW', code: '267', label: 'Botswana' },
          { value: 'BV', code: '', label: 'Bouvet Island' },
          { value: 'BR', code: '55', label: 'Brazil' },
          { value: 'IO', code: '246', label: 'British lndian Ocean Territory' },
          { value: 'BN', code: '673', label: 'Brunei Darussalam' },
          { value: 'BG', code: '359', label: 'Bulgaria' },
          { value: 'BF', code: '226', label: 'Burkina Faso' },
          { value: 'BI', code: '257', label: 'Burundi' },
          { value: 'KH', code: '855', label: 'Cambodia' },
          { value: 'CM', code: '237', label: 'Cameroon' },
          { value: 'CV', code: '238', label: 'Cape Verde' },
          { value: 'KY', code: '1', label: 'Cayman Islands' },
          { value: 'CF', code: '236', label: 'Central African Republic' },
          { value: 'TD', code: '235', label: 'Chad' },
          { value: 'CL', code: '56', label: 'Chile' },
          { value: 'CX', code: '61', label: 'Christmas Island' },
          { value: 'CC', code: '61', label: 'Cocos (Keeling) Islands' },
          { value: 'CO', code: '57', label: 'Colombia' },
          { value: 'KM', code: '269', label: 'Comoros' },
          { value: 'CG', code: '242', label: 'Congo' },
          { value: 'CK', code: '682', label: 'Cook Islands' },
          { value: 'CR', code: '506', label: 'Costa Rica' },
          { value: 'HR', code: '385', label: 'Croatia (Hrvatska)' },
          { value: 'CU', code: '53', label: 'Cuba' },
          { value: 'CY', code: '357', label: 'Cyprus' },
          { value: 'CZ', code: '420', label: 'Czech Republic' },
          { value: 'CD', code: '243', label: 'Democratic Republic of Congo' },
          { value: 'DK', code: '45', label: 'Denmark' },
          { value: 'DJ', code: '253', label: 'Djibouti' },
          { value: 'DM', code: '1', label: 'Dominica' },
          { value: 'DO', code: '1', label: 'Dominican Republic' },
          { value: 'EC', code: '593', label: 'Ecuador' },
          { value: 'EG', code: '20', label: 'Egypt' },
          { value: 'SV', code: '503', label: 'El Salvador' },
          { value: 'GQ', code: '240', label: 'Equatorial Guinea' },
          { value: 'ER', code: '291', label: 'Eritrea' },
          { value: 'EE', code: '372', label: 'Estonia' },
          { value: 'ET', code: '251', label: 'Ethiopia' },
          { value: 'FK', code: '500', label: 'Falkland Islands (Malvinas)' },
          { value: 'FO', code: '298', label: 'Faroe Islands' },
          { value: 'FJ', code: '679', label: 'Fiji' },
          { value: 'FI', code: '358', label: 'Finland' },
          { value: 'FR', code: '33', label: 'France' },
          { value: 'GF', code: '594', label: 'French Guiana' },
          { value: 'PF', code: '689', label: 'French Polynesia' },
          { value: 'TF', code: '', label: 'French Southern Territories' },
          { value: 'GA', code: '241', label: 'Gabon' },
          { value: 'GM', code: '220', label: 'Gambia' },
          { value: 'GE', code: '995', label: 'Georgia' },
          { value: 'DE', code: '49', label: 'Germany' },
          { value: 'GH', code: '233', label: 'Ghana' },
          { value: 'GI', code: '350', label: 'Gibraltar' },
          { value: 'GR', code: '30', label: 'Greece' },
          { value: 'GL', code: '299', label: 'Greenland' },
          { value: 'GD', code: '1', label: 'Grenada' },
          { value: 'GP', code: '590', label: 'Guadeloupe' },
          { value: 'GU', code: '1', label: 'Guam' },
          { value: 'GT', code: '502', label: 'Guatemala' },
          { value: 'GN', code: '224', label: 'Guinea' },
          { value: 'GW', code: '245', label: 'Guinea-Bissau' },
          { value: 'GY', code: '592', label: 'Guyana' },
          { value: 'HT', code: '509', label: 'Haiti' },
          { value: 'HM', code: '', label: 'Heard and Mc Donald Islands' },
          { value: 'HN', code: '504', label: 'Honduras' },
          { value: 'HK', code: '852', label: 'Hong Kong' },
          { value: 'HU', code: '36', label: 'Hungary' },
          { value: 'IS', code: '354', label: 'Iceland' },
          { value: 'IN', code: '91', label: 'India' },
          { value: 'ID', code: '62', label: 'Indonesia' },
          { value: 'IR', code: '98', label: 'Iran (Islamic Republic of)' },
          { value: 'IQ', code: '964', label: 'Iraq' },
          { value: 'IE', code: '353', label: 'Ireland' },
          { value: 'IL', code: '972', label: 'Israel' },
          { value: 'CI', code: '225', label: 'Ivory Coast' },
          { value: 'JM', code: '1', label: 'Jamaica' },
          { value: 'JP', code: '81', label: 'Japan' },
          { value: 'JO', code: '962', label: 'Jordan' },
          { value: 'KZ', code: '7', label: 'Kazakhstan' },
          { value: 'KE', code: '254', label: 'Kenya' },
          { value: 'KI', code: '686', label: 'Kiribati' },
          { value: 'KR', code: '82', label: 'Korea' },
          { value: 'KW', code: '965', label: 'Kuwait' },
          { value: 'KG', code: '996', label: 'Kyrgyzstan' },
          { value: 'LA', code: '856', label: "Lao People's Democratic Republic" },
          { value: 'LV', code: '371', label: 'Latvia' },
          { value: 'LB', code: '961', label: 'Lebanon' },
          { value: 'LS', code: '266', label: 'Lesotho' },
          { value: 'LR', code: '231', label: 'Liberia' },
          { value: 'LY', code: '218', label: 'Libyan Arab Jamahiriya' },
          { value: 'LI', code: '423', label: 'Liechtenstein' },
          { value: 'LT', code: '370', label: 'Lithuania' },
          { value: 'LU', code: '352', label: 'Luxembourg' },
          { value: 'MO', code: '853', label: 'Macau' },
          { value: 'MK', code: '389', label: 'Macedonia' },
          { value: 'MG', code: '261', label: 'Madagascar' },
          { value: 'MW', code: '265', label: 'Malawi' },
          { value: 'MY', code: '60', label: 'Malaysia' },
          { value: 'MV', code: '960', label: 'Maldives' },
          { value: 'ML', code: '223', label: 'Mali' },
          { value: 'MT', code: '356', label: 'Malta' },
          { value: 'MH', code: '692', label: 'Marshall Islands' },
          { value: 'MQ', code: '596', label: 'Martinique' },
          { value: 'MR', code: '222', label: 'Mauritania' },
          { value: 'MU', code: '230', label: 'Mauritius' },
          { value: 'FM', code: '691', label: 'Micronesia' },
          { value: 'MD', code: '373', label: 'Moldova' },
          { value: 'MC', code: '377', label: 'Monaco' },
          { value: 'MN', code: '976', label: 'Mongolia' },
          { value: 'MS', code: '1', label: 'Montserrat' },
          { value: 'MA', code: '212', label: 'Morocco' },
          { value: 'MZ', code: '258', label: 'Mozambique' },
          { value: 'MM', code: '95', label: 'Myanmar' },
          { value: 'NA', code: '264', label: 'Namibia' },
          { value: 'NR', code: '674', label: 'Nauru' },
          { value: 'NP', code: '977', label: 'Nepal' },
          { value: 'NL', code: '31', label: 'Netherlands' },
          { value: 'AN', code: '', label: 'Netherlands Antilles' },
          { value: 'NC', code: '687', label: 'New Caledonia' },
          { value: 'NZ', code: '64', label: 'New Zealand' },
          { value: 'NI', code: '505', label: 'Nicaragua' },
          { value: 'NE', code: '227', label: 'Niger' },
          { value: 'NG', code: '234', label: 'Nigeria' },
          { value: 'NU', code: '683', label: 'Niue' },
          { value: 'NF', code: '672', label: 'Norfork Island' },
          { value: 'MP', code: '1', label: 'Northern Mariana Islands' },
          { value: 'NO', code: '47', label: 'Norway' },
          { value: 'OM', code: '968', label: 'Oman' },
          { value: 'PK', code: '92', label: 'Pakistan' },
          { value: 'PW', code: '680', label: 'Palau' },
          { value: 'PA', code: '507', label: 'Panama' },
          { value: 'PG', code: '675', label: 'Papua New Guinea' },
          { value: 'PY', code: '595', label: 'Paraguay' },
          { value: 'PE', code: '51', label: 'Peru' },
          { value: 'PH', code: '63', label: 'Philippines' },
          { value: 'PN', code: '', label: 'Pitcairn' },
          { value: 'PL', code: '48', label: 'Poland' },
          { value: 'PT', code: '351', label: 'Portugal' },
          { value: 'PR', code: '1', label: 'Puerto Rico' },
          { value: 'QA', code: '974', label: 'Qatar' },
          { value: 'SS', code: '211', label: 'Republic of South Sudan' },
          { value: 'RE', code: '262', label: 'Reunion' },
          { value: 'RO', code: '40', label: 'Romania' },
          { value: 'RU', code: '7', label: 'Russian Federation' },
          { value: 'RW', code: '250', label: 'Rwanda' },
          { value: 'KN', code: '1', label: 'Saint Kitts and Nevis' },
          { value: 'LC', code: '1', label: 'Saint Lucia' },
          { value: 'VC', code: '1', label: 'Saint Vincent and the Grenadines' },
          { value: 'WS', code: '685', label: 'Samoa' },
          { value: 'SM', code: '378', label: 'San Marino' },
          { value: 'ST', code: '239', label: 'Sao Tome and Principe' },
          { value: 'SA', code: '966', label: 'Saudi Arabia' },
          { value: 'SN', code: '221', label: 'Senegal' },
          { value: 'RS', code: '381', label: 'Serbia' },
          { value: 'SC', code: '248', label: 'Seychelles' },
          { value: 'SL', code: '232', label: 'Sierra Leone' },
          { value: 'SG', code: '65', label: 'Singapore' },
          { value: 'SK', code: '421', label: 'Slovakia' },
          { value: 'SI', code: '386', label: 'Slovenia' },
          { value: 'SB', code: '677', label: 'Solomon Islands' },
          { value: 'SO', code: '252', label: 'Somalia' },
          { value: 'ZA', code: '27', label: 'South Africa' },
          { value: 'GS', code: '', label: 'South Georgia South Sandwich Islands' },
          { value: 'ES', code: '34', label: 'Spain' },
          { value: 'LK', code: '94', label: 'Sri Lanka' },
          { value: 'SH', code: '290', label: 'St. Helena' },
          { value: 'PM', code: '508', label: 'St. Pierre and Miquelon' },
          { value: 'SD', code: '249', label: 'Sudan' },
          { value: 'SR', code: '597', label: 'Suriname' },
          { value: 'SJ', code: '47', label: 'Svalbarn and Jan Mayen Islands' },
          { value: 'SZ', code: '268', label: 'Swaziland' },
          { value: 'SE', code: '46', label: 'Sweden' },
          { value: 'CH', code: '41', label: 'Switzerland' },
          { value: 'SY', code: '963', label: 'Syrian Arab Republic' },
          { value: 'TW', code: '886', label: 'Taiwan' },
          { value: 'TJ', code: '992', label: 'Tajikistan' },
          { value: 'TZ', code: '255', label: 'Tanzania' },
          { value: 'TH', code: '66', label: 'Thailand' },
          { value: 'TG', code: '228', label: 'Togo' },
          { value: 'TK', code: '690', label: 'Tokelau' },
          { value: 'TO', code: '676', label: 'Tonga' },
          { value: 'TT', code: '1', label: 'Trinidad and Tobago' },
          { value: 'TN', code: '216', label: 'Tunisia' },
          { value: 'TR', code: '90', label: 'Turkey' },
          { value: 'TM', code: '993', label: 'Turkmenistan' },
          { value: 'TC', code: '1', label: 'Turks and Caicos Islands' },
          { value: 'TV', code: '688', label: 'Tuvalu' },
          { value: 'UG', code: '256', label: 'Uganda' },
          { value: 'UA', code: '380', label: 'Ukraine' },
          { value: 'AE', code: '971', label: 'United Arab Emirates' },
          { value: 'GB', code: '44', label: 'United Kingdom' },
          { value: 'UM', code: '', label: 'United States minor outlying islands' },
          { value: 'UY', code: '598', label: 'Uruguay' },
          { value: 'UZ', code: '998', label: 'Uzbekistan' },
          { value: 'VU', code: '678', label: 'Vanuatu' },
          { value: 'VA', code: '39', label: 'Vatican City State' },
          { value: 'VE', code: '58', label: 'Venezuela' },
          { value: 'VN', code: '84', label: 'Vietnam' },
          { value: 'VG', code: '1', label: 'Virgin Islands (British)' },
          { value: 'VI', code: '1', label: 'Virgin Islands (U.S.)' },
          { value: 'WF', code: '681', label: 'Wallis and Futuna Islands' },
          { value: 'EH', code: '212', label: 'Western Sahara' },
          { value: 'YE', code: '967', label: 'Yemen' },
          { value: 'ZM', code: '260', label: 'Zambia' },
          { value: 'ZW', code: '263', label: 'Zimbabwe' },
          { value: 'GLB', code: '000', label: 'Global' },
        ]
      )
  
      return {
        t,
        countries
      }
    },
    methods: {
      changeLanguage(locale) {
        this.$i18n.locale = locale
      },
      goUp() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      },
      toggleDropdown(isBtn) {
        if (isBtn && this.dropdownOpen)  this.dropdownOpen = true
        else this.dropdownOpen = !this.dropdownOpen
      },
      contactos(){
        
        let data = {
          name: this.name,
          email: this.email,
          country: this.country,
          phone: this.phone,
          company: this.company,
          service: this.service,
          solicitud: this.request,
        }
        this.axios.post('https://aurora.linc-ca.com/api/v1/hubspot/search', data)
        //this.axios.post('http://127.0.0.1:8000/api/v1/hubspot/search', data)
        .then( response => {
          console.log(response)
          if(response.data.success == true){
            this.success = true;
            this.$gtag.event('formulario_contacto', { method: 'Google' })
            this.name = ""
            this.email= ""
            this.country = ""
            this.phone = ""
            this.company = ""
            this.service = ""
            this.request = ""
          } else{
            if(response.data.message == 'Validation errors'){
              this.error = true;
              this.error_msg = response.data.data;
              /*response.data.data.forEach(element => {
                console.log(element)
                toast(element, {
                  autoClose: 1000,
                }); // ToastOptions
              });*/
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
    computed: {
    countriesWithFlags() {
      return this.countries.map(country => ({
        ...country,
        flagSrc: require(`../assets/${country.value.toLowerCase()}.png`)
      }));
    },
  },
  }
</script>

  
  <style>
.contactanos-linc {
  background-color: white;
  padding: 3em 5em;
}

.btn-bottom-contactanos {
    border-radius: 2em;
    background-color: #004E7D;
    color: white;
    width: 80%;
    text-align: center;
    font-size: x-large;
    padding: 0.2em;
  }
.form-contactanos {
    padding: 1em;
    background: #EBF1F5;
    border-radius: 2em;
  }

  .btn-send {
  background: #004E7D;
  width: inherit;
  color: white;
  border-radius: 5em;
  border: 0;
  height: 2em;
}

  @media (max-width: 767px) {
  .contactanos-linc {
    background-color: #EBF1F5;
    padding: 2em;
    }
    .btn-bottom-contactanos {
    border-radius: 2em;
    background-color: #004E7D;
    color: white;
    width: 100%;
    text-align: center;
    font-size: x-large;
    padding: 0.2em;
  }

}
  </style>
  