<template>
    <div class="footer-bar" >
        <a type="button" class="btn-go-home" @click="goUp()">
            <font-awesome-icon :icon="['fas', 'arrow-up']" style="color: white; font-size:xx-large; margin-top: 0.1em;"/>
        </a>
        <div class="container cont-footer " style="margin-top: 2em">
            <div class="row gx-5">
                <div class="col-4" style="padding: 0;">
                    <div class="p-3 row" style="margin-left: 1em;">
                        <img class="col logo-footer" src="../assets/icon-white.png" alt="" width="30" height="24">
                        <div class="col content-socials-icons">
                            <a type="button" href="https://www.facebook.com/conexionlinc" target="_blank">
                                <font-awesome-icon class="icon-footer" :icon="['fab', 'facebook']" size="lg"/>
                            </a>
                            <a type="button" href="https://www.instagram.com/lincgrupo/" target="_blank">
                                <font-awesome-icon class="icon-footer" :icon="['fab', 'instagram']" size="lg"/>
                            </a>
                            <a type="button" href="https://www.linkedin.com/company/grupo-linc" target="_blank">
                                <font-awesome-icon class="icon-footer" :icon="['fab', 'linkedin']" size="lg"/>
                            </a>
                        </div>
                    </div>
                    <h3 class="p-3" style="color: white; font-weight: 500; margin-left: 1em;s">
                        {{ $t('message.somos_tecnologia') }}
                    </h3>
                </div>
                <div class="col-8">
                <div class="p-3 row content-links">
                    <div class="col">
                        <ul class="nav flex-column">
                            <h5 style="color: white;" >{{ $t('message.nuestros_servicios') }}</h5>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" href="/nuestros-servicios#nos-diferencia-section" style="color: white; text-decoration: auto;">{{ $t('message.que_nos_diferencia') }}</a>
                            </li>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" href="/nuestros-servicios#nuestra-soluciones-section" style="color: white; text-decoration: auto;">{{ $t('message.nuestras_soluciones') }}</a>
                            </li>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" href="/nuestros-servicios#soluciones-adicionales-section" style="color: white; text-decoration: auto;">{{ $t('message.soluciones_adicionales') }}</a>
                            </li>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" href="/nuestros-servicios#nuestro-compromiso-section" style="color: white; text-decoration: auto;">{{ $t('message.nuestro_compromiso') }}</a>
                            </li>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" href="/nuestros-servicios#aliados-estrategicos-section" style="color: white; text-decoration: auto;">{{ $t('message.aliados') }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col">
                        <ul class="nav flex-column">
                            <h5 style="color: white;" >{{ $t('message.quienes_somos') }}</h5>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" href="/quienes-somos#nuestra-historia-section" style="color: white; text-decoration: auto;">{{ $t('message.nuestra_historia') }}</a>
                            </li>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" href="/quienes-somos#nuestro-enfoque-section" style="color: white; text-decoration: auto;">{{ $t('message.nuestro_enfoque') }}</a>
                            </li>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" href="/quienes-somos#nuestros-principios-section" style="color: white; text-decoration: auto;">{{ $t('message.nuestros_principios') }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col">
                        <ul class="nav flex-column title-contactanos" style="padding-left: 0;">
                            <h5 style="color: white;">{{ $t('message.ayuda') }}</h5>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" href="/contactanos" style="color: white; text-decoration: auto;">{{ $t('message.contactanos') }}</a>
                            </li>
                            <li class="nav-item" style="margin: .5em 0px;">
                            <a class="nav-link-item" style="color: white; text-decoration: auto;">{{ $t('message.blog') }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="container" :style="{'height': dropdownOpenGua || dropdownOpenNic || dropdownOpenCost || dropdownOpenPan ? '50vh' : 'initial'}">
            <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
                <div class="col" style="padding-right: 1em; padding-left: 1em;">
                    <div class="dropdown d-flex">
                    <button class="btn btn-show-info" type="button" id="dropdownMenuButton1" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleDropdownGua()" @blur="closeDropdownGua()">
                        <div class="d-flex justify-content-center gap-2" style="align-items: center;">
                            <h4 style="margin-left: 0.5em;">Guatemala</h4>
                            <img src="../assets/guatemala.png" alt=""  style="width: 2.7em; height: 2.7em;">
                            <font-awesome-icon :icon="['fas', 'chevron-down']"/>
                        </div>
                    </button>
                    <ul class="dropdown-menu content-contacts-places" aria-labelledby="dropdownMenuButton1">
                        <div class="text-center mt-2">
                            <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg" style="color: #0E4989"/><br>
                            <a style="text-decoration: auto;" href="https://goo.gl/maps/Fd4Wzgs9QfgHJRYu9">
                                <h7 style="color: #0E4989">19 Avenida 2-78, Colonia, El Mirador 1,</h7>
                                <h7 style="color: #0E4989">Nivel 10.Oficina 1002, Zona 11.</h7>
                                <h7 style="color: #0E4989">Guatemala - Guatemala.</h7>
                            </a><br>    
                            <font-awesome-icon class="mt-3" :icon="['fas', 'phone-alt']" size="lg" style="color: #0E4989"/><br>                  
                            <a style="text-decoration: auto;" href="tel:+50222938953"><h7 class="text-center" style="color: #0E4989">+502 2293 8953</h7></a><br>
                            <a style="text-decoration: auto;" href="tel:+50222786731"><h7 class="text-center" style="color: #0E4989">+502 2278 6731</h7></a>
                        </div>
                    </ul>
                </div>
                </div>
                <div class="col" style="padding-right: 1em; padding-left: 1em;">
                    <div class="dropdown d-flex">
                    <button class="btn btn-show-info" type="button" id="dropdownMenuButton1" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleDropdownNic()" @blur="closeDropdownNic()">
                        <div class="d-flex justify-content-center gap-2" style="align-items: center;">
                            <h4 style="margin-left: 0.5em; margin-bottom: 0.1em;">Nicaragua</h4>
                            <img src="../assets/nicaragua.png" alt=""  style="width: 2.7em; height: 2.7em;">
                            <font-awesome-icon :icon="['fas', 'chevron-down']"/>
                        </div>
                    </button>
                    <ul class="dropdown-menu content-contacts-places" aria-labelledby="dropdownMenuButton1">
                        <div class="text-center mt-2">
                            <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg" style="color: #0E4989"/><br>
                            <a style="text-decoration: auto;" href="https://goo.gl/maps/Fd4Wzgs9QfgHJRYu9">
                                <h7 style="color: #0E4989">Casa Pellas Acahualinca 2 cuadras</h7>
                                <h7 style="color: #0E4989">abajo, Almacén SEMAR 2do. Piso.</h7>
                                <h7 style="color: #0E4989">Managua - Nicaragua.</h7>
                            </a><br>    
                            <font-awesome-icon class="mt-3" :icon="['fas', 'phone-alt']" size="lg" style="color: #0E4989"/><br>                  
                            <a style="text-decoration: auto;" href="tel:+50522683105"><h7 class="text-center" style="color: #0E4989">+505 2265 3705</h7></a><br>
                            <h7 class="text-center" style="color: #0E4989">+505 2268 3105</h7>
                        </div>
                    </ul>
                </div>
                </div>
                <div class="col" style="padding-right: 1em; padding-left: 1em;">
                    <div class="dropdown d-flex">
                    <button class="btn btn-show-info" type="button" id="dropdownMenuButton1" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleDropdownCost()" @blur="closeDropdownCost()">
                        <div class="d-flex justify-content-center gap-2" style="align-items: center;">
                            <h4 style="margin-left: 0.5em; margin-bottom: 0.1em;">Costa Rica</h4>
                            <img src="../assets/costarica.png" alt=""  style="width: 2.5em; height: 2.5em;">
                            <font-awesome-icon :icon="['fas', 'chevron-down']"/>
                        </div>
                    </button>
                    <ul class="dropdown-menu content-contacts-places" aria-labelledby="dropdownMenuButton1">
                        <div class="text-center mt-2">
                            <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg" style="color: #0E4989"/><br>
                            <a style="text-decoration: auto;" href="https://goo.gl/maps/VK4Ru83F2u6YGNJ18">
                                <h7 style="color: #0E4989">Zona Franca BES, Edificio E6, 2do piso.</h7>
                                <h7 style="color: #0E4989">El Coyol, Alajuela.</h7>
                            </a><br>
                            <font-awesome-icon class="mt-4" :icon="['fas', 'phone-alt']" size="lg" style="color: #0E4989"/><br>                  
                            <a style="text-decoration: auto;" href="tel:+50640012290"><h7 class="text-center" style="color: #0E4989">+506 4001 2290</h7></a>
                        </div>
                    </ul>
                </div>
                </div>
                <div class="col" style="padding-right: 1em; padding-left: 1em;">
                    <div class="dropdown d-flex">
                    <button class="btn btn-show-info" type="button" id="dropdownMenuButton1" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleDropdownPan()" @blur="closeDropdownPan()">
                    <div class="d-flex justify-content-center gap-2" style="align-items: center;">
                        <h4 style="margin-left: 0.5em; margin-bottom: 0.1em;">Panamá</h4>
                        <img src="../assets/panama.png" alt="" style="width: 2.5em; height: 2.5em;">
                        <font-awesome-icon :icon="['fas', 'chevron-down']"/>
                    </div>
                    </button>
                    <ul class="dropdown-menu content-contacts-places" aria-labelledby="dropdownMenuButton1">
                    <div class="text-center mt-2">
                        <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg" style="color: #0E4989"/><br>
                        <a style="text-decoration: auto;" href="https://goo.gl/maps/VK4Ru83F2u6YGNJ18">
                        <h7 style="color: #0E4989">Calle 54 Obarrio piso 26, Oficina 18 del </h7>
                        <h7 style="color: #0E4989">Edificio Atrium Tower, Panamá City.</h7>
                        </a><br>
                        <font-awesome-icon class="mt-4" :icon="['fas', 'phone-alt']" size="lg" style="color: #0E4989"/><br>                  
                        <a style="text-decoration: auto;" href="tel:+50769483832"><h7 class="text-center" style="color: #0E4989">+507 6948-3832</h7></a>
                    </div>
                    </ul>
                </div>
                </div>

            </div>
        </div>
            <div class="content-copy">
                <p style="margin-right: 5px;">&copy;</p>
                <div class="d-flex">
                    <span style="margin-right: .5em;">{{ 'Grupo Linc 2023 ' }}</span>
                    <a class="text-politicas" href="https://aurorabot.linc-ca.com/images/Pol%C3%ADtica%20de%20privacidad%20Grupo%20Linc%20Espa%C3%B1ol.pdf" download> {{ $t('message.politicas') }}</a>
                    <a class="text-politicas" href="https://aurorabot.linc-ca.com/images/T%C3%A9rminos%20y%20Condiciones%20Espa%C3%B1ol.pdf" download> {{ $t('message.terminos') }}</a>
                </div>

            </div>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  import { ref } from 'vue'
  
  export default {
    setup() {
      const { t } = useI18n()
      const dropdownOpenGua = ref(false)
      const dropdownOpenNic = ref(false)
      const dropdownOpenCost = ref(false)
      const dropdownOpenPan = ref(false)

  
      return {
        t,
        dropdownOpenGua,
        dropdownOpenNic,
        dropdownOpenCost,
        dropdownOpenPan,
      }
    },
    methods: {

        closeDropdownGua() {
            this.dropdownOpenGua = false
        },
        closeDropdownNic() {
            this.dropdownOpenNic = false
        },
        closeDropdownPan() {
            this.dropdownOpenPan = false
        },
        closeDropdownCost() {
            this.dropdownOpenCost = false
        },
        changeLanguage(locale) {
            this.$i18n.locale = locale
        },
        goUp() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        toggleDropdownGua() {
            if (this.dropdownOpenNic) this.dropdownOpenNic = false
            if (this.dropdownOpenCost) this.dropdownOpenCost = false
            if (this.dropdownOpenPan) this.dropdownOpenPan = false
            this.dropdownOpenGua = !this.dropdownOpenGua
        },
        toggleDropdownNic(){
            if (this.dropdownOpenGua) this.dropdownOpenGua = false
            if (this.dropdownOpenCost) this.dropdownOpenCost = false
            if (this.dropdownOpenPan) this.dropdownOpenPan = false
            this.dropdownOpenNic = !this.dropdownOpenNic
        },
        toggleDropdownCost(){
            if (this.dropdownOpenNic) this.dropdownOpenNic = false
            if (this.dropdownOpenGua) this.dropdownOpenGua = false
            if (this.dropdownOpenPan) this.dropdownOpenPan = false
            this.dropdownOpenCost = !this.dropdownOpenCost
        },
        toggleDropdownPan(){
            if (this.dropdownOpenNic) this.dropdownOpenNic = false
            if (this.dropdownOpenCost) this.dropdownOpenCost = false
            if (this.dropdownOpenGua) this.dropdownOpenGua = false
            this.dropdownOpenPan = !this.dropdownOpenPan
        },
    },
  }
  </script>
  
  <style scoped>
.footer-bar {
    padding: 2em 0em;
    height: auto;
    background-color: #172C49;
}
.cont-footer {
    max-width: inherit;
    background-color: #172C49;
    padding-bottom: 1em;
    padding-right: 3rem!important;
    padding-left: 3rem!important;
}
.icon-footer {
    color: white;
    font-size: 2em;
}
.content-socials-icons {
    display: flex;
    gap: 2em;
    align-items: center;
    justify-content: right;
}
.logo-footer {
    max-width: fit-content;
    height: 6em;
}
.content-contacts-places {
    background: #E6EDF2;
    padding: 1em;
    border-radius: 2em;
}
.content-links {
    margin-left: 10em;
}
.content-copy {
    background-color: #172C49;
    color: white;
    display: flex;
    justify-content: center;   
    margin-top: 1.5em
}
.btn-go-home {
    float: right;
    margin-top: -5%;
    margin-right: 4%;
    width: 5em;
    height: 5em;
    background: #004E7D;
    border-radius: 10em;
    text-align: center;
    padding: 1.5%;
}
.btn-show-info {
    color: white;
    display: flex;
    align-items: center;
}

.btn:focus {
    box-shadow: none;
}

.btn:hover {
    color: white
}
.btn-go-home:hover {
    background: #337197;
}
.text-politicas {
    text-decoration: auto;
    color: white
}

@media (max-width: 767px) {
  .btn-go-home {
    display: block;
    margin-top: -20%;
    padding: 1em;
    width: 4em;
    height: 4em;
  }

  .content-links {
    margin-left: 0em;
}
  .cont-footer {
    padding: 0;
    padding-left: 1em!important;
    padding-right: 1em!important;
  }

  .col {
    width: 100%;
  }

  .p-3.text-center {
    text-align: center;
  }


  .btn-bottom {
    width: 100%;
  }

  .logo-footer {
    width: auto;
    height: 5em;
    margin-bottom: 1em;
  }
  .icon-footer {
    font-size: 24px;
    margin-right: 10px;
  }

  .content-contacts-places h3,
  .content-contacts-places h7,
  .text-especiales {
    font-size: 14px;
  }

  .icon-go-up {
    font-size: 28px;
  }

  .content-contacts-places h5 {
    font-size: 16px;
  }

  .content-contacts-places .icon-footer {
    font-size: 18px;
  }
  .content-socials-icons {
    gap: 1em;
    display: grid;
  }
  .nav-link-item {
    font-size: small;
  }
  .title-contactanos {
    padding-left: 0;
  }

  .cont-footer-countries .dropdown-menu.show {
    height: 50vh;
  }
}

</style>
  