<template>
  <div class="contactanos-servicios">
    <div class="row row-cols-1 row-cols-md-2">
      <div class="col text-center">
        <img src="../assets/contactanos-image.png" style="width: 75%;" alt="...">
      </div>
      <div class="col text-center" style="align-self: center;">
        <h3 style="color: #172C49; margin-bottom: 1em;">{{ $t('message.podemos_ayudarte') }}</h3>
        <h5 style="color: #004E7D; margin-top: 1em;">{{ $t('message.especial_description') }}</h5>
        <div class="d-grid gap-2 col-10 col-md-6" style="margin-top: 2em; margin-left: auto; margin-right: auto;">
          <a href="/contactanos" class="btn-bottom-contactanos" style="text-decoration: auto;" type="button">{{ $t('message.contactanos') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

</script>

<style>
.contactanos-servicios {
  background-color: #EBF1F5;
  padding: 3em 5em;
}
.btn-bottom-contactanos {
  border-radius: 2em;
  background-color: #004E7D;
  color: white;
  width: 80%;
  text-align: center;
  font-size: x-large;
  padding: 0.2em;
}

.btn-bottom-contactanos:hover {
  background-color: #A6C2E1;
  color: black;
}

@media (max-width: 767px) {
  .contactanos-servicios {
    padding: 2em;
  }
  .btn-bottom-contactanos {
    width: 100%;
  }
}
</style>
