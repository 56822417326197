<template>
    <div class="nuestra-historia">
        <h2 class="title-section">{{ $t('message.nuestra_historia') }}</h2>
        <div class="content-subtitle"><h3 style="color: #004E7D; ">{{ $t('message.trayectoria') }}</h3>
        </div>

        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" style="height: 20em; margin-top: 3em;">
          <div class="carousel-inner">
            <div class="carousel-item active" style="text-align: -webkit-center;">
              <div class="d-flex content-info-history">
                <div class="content-text-year col">
                  <span>2023</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
                <div class="content-text-history col" style="text-align: -webkit-center;">
                  <div style="margin-top: 2em;">{{  $t('message.description_2012') }}</div>
                  <div class="bubble-line" style="margin-top:1em;"></div>
                </div>
                <div class="content-text-year col">
                  <span>2013</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
              </div>
              <div class="my-time-item-selected">2012</div>
            </div>
            <div class="carousel-item" style="text-align: -webkit-center;">
              <div class="d-flex content-info-history">
                <div class="content-text-year col">
                  <span>2012</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
                <div class="content-text-history col" style="text-align: -webkit-center;">
                  <div style="margin-top: 1em;">{{  $t('message.description_2013') }}</div>
                  <div class="bubble-line" style="margin-top:1em;"></div>
                </div>
                <div class="content-text-year col">
                  <span>2017</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
              </div>
              <div class="my-time-item-selected">2013</div>
            </div>
            <div class="carousel-item" style="text-align: -webkit-center;">
              <div class="d-flex content-info-history">
              <div class="content-text-year col">
                <span>2013</span>
                <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
              </div>
              <div class="content-text-history col" style="text-align: -webkit-center;">
                <span>{{  $t('message.description_2017') }}</span>
                <div class="bubble-line" style="margin-top: 0.4em"></div>
              </div>
              <div class="content-text-year col">
                <span>2019</span>
                <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
              </div>
              </div>
              <div class="my-time-item-selected">2017</div>
            </div>
            <div class="carousel-item" style="text-align: -webkit-center;">
              <div class="d-flex content-info-history">
                <div class="content-text-year col">
                  <span>2017</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
                <div class="content-text-history col" style="text-align: -webkit-center;">
                  <div style="margin-top: 2em;">{{  $t('message.description_2019') }}</div>
                  <div class="bubble-line" style="margin-top:1em;"></div>
                </div>
                <div class="content-text-year col">
                  <span>2020</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
              </div>
              <div class="my-time-item-selected">2019</div>
            </div>
            <div class="carousel-item" style="text-align: -webkit-center;">
              <div class="d-flex content-info-history">
                <div class="content-text-year col">
                  <span>2019</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
                <div class="content-text-history col" style="text-align: -webkit-center;">
                  <div style="margin-top: 1em;">{{ $t('message.description_2020') }}</div>
                  <div class="bubble-line" style="margin-top:.8em;"></div>
                </div>
                <div class="content-text-year col">
                  <span>2021</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
              </div>
              <div class="my-time-item-selected">2020</div>
            </div>
            <div class="carousel-item" style="text-align: -webkit-center;">
              <div class="d-flex content-info-history">
                <div class="content-text-year col">
                  <span>2020</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
                <div class="content-text-history col" style="text-align: -webkit-center;">
                  <div style="margin-top: 1.5em;">{{  $t('message.description_2021') }}</div>
                  <div class="bubble-line" style="margin-top: 0.5em;"></div>
                </div>
                <div class="content-text-year col">
                  <span>2022</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
                </div>
                <div class="my-time-item-selected">2021</div>
            </div>
            <div class="carousel-item" style="text-align: -webkit-center;">
              <div class="d-flex content-info-history">
                <div class="content-text-year col">
                  <span>2021</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
                <div class="content-text-history col" style="text-align: -webkit-center;">
                  <div style="margin-top: 0.8em;"> {{  $t('message.description_2022') }}</div>
                  <div class="bubble-line" style="margin-top:1em;"></div>
                </div>
                <div class="content-text-year col">
                  <span>2023</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
              </div>
              <div class="my-time-item-selected">2022</div>
            </div>
            <div class="carousel-item" style="text-align: -webkit-center;">
              <div class="d-flex content-info-history">
                <div class="content-text-year col">
                  <span>2022</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
                <div class="content-text-history col" style="text-align: -webkit-center;">
                  <span>{{  $t('message.description_2023') }}</span>
                  <div class="bubble-line" style="margin-top: 0.5em;"></div>
                </div>
                <div class="content-text-year col">
                  <span>2013</span>
                  <div style="height: 1.5em; width: 1.5em; background: #EBF1F5; transform: rotate(45deg);"></div>
                </div>
              </div>
              <div class="my-time-item-selected">2023</div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center line">
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style="margin-top: 8.5em; width: 11%;">
              <font-awesome-icon :icon="['fas', 'chevron-left']" style="color: white; background: #337197; font-size: 3em; padding: 10px 5px; border-radius: 25px; width: 1em"/>
              <span class="visually-hidden">Previous</span>
            </button>

            <div class="line-time" style="width: 100%; place-items: center; justify-content: center;">
              <div style="background: linear-gradient(to right, #E42037, #CB253F)" class="my-time-line"></div>
              <div class="my-time-item"></div>
              <div style="background: linear-gradient(to right, #C42741, #9A2F4E)" class="my-time-line"></div>
              <div style="background: linear-gradient(to right, #C42741, #9A2F4E)" class="my-time-line"></div>
              <div class="my-time-item"></div>
              <div style="background: linear-gradient(to right, #3E426A, #044D7C)" class="my-time-line"></div>
            </div>

            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" style="margin-top: 8.5em; width: 11%;">
              <font-awesome-icon :icon="['fas', 'chevron-right']" style="color: white; background: #337197; font-size: 3em; padding: 10px 5px; border-radius: 25px; width: 1em"/>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>

    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  }
  }

  </script>
  
  <style scoped>
.nuestra-historia {
    padding: 4em;
}
.title-section {
    color: #004E7D;
    width: 50%;
    font-weight: 700;
}
.content-info-history {
  width: 37em;
}
.content-subtitle {
  text-align: center;
  padding-left: 35%;
  padding-right: 35%;
}
.my-time-line {
  height: 0.5em;
  width: 20%;
}
.my-time-item {
  width: 2em;
  height: 2em;
  border-radius: 10em;
  background-color: #EBF1F5;
  border: 5px solid #172C49;
  cursor: pointer;
}
.my-time-item-selected {
  margin-top: 3em;
  width: 5em;
  height: 5em;
  border-radius: 10em;
  background-color: #172C49;
  color: white;
  padding: 2.5% 2%;
  text-align: center;
  font-weight: 700;
  font-size: large;
}
.bubble-line {
  width: 4em;
  height: 4em;
  background: #EBF1F5;
  transform: rotate(45deg);
}
.content-text-history {
  padding: 3%;
  border-radius: 2em;
  background-color: #EBF1F5;
  height: 8em;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  color: #004E7D;
  font-weight: 500;
}
.content-popups {
  margin-top: 5em;
  padding-left: 22%;
  padding-right: 22%;
  align-items: center;
}
.content-text-year {
  border-radius: 1em;
  padding: 1.75% 3%;
  background-color: #EBF1F5;
  height: 3em;
  max-width: max-content;
  margin-top: auto;
  color: #004E7D;
  font-weight: 600;
}
.btn-move {
  background-color: #EBF1F5;
  border-radius: 10em;
  border: 0;
  height: 2em;
  width: 2.5em;
}
.icon-move {
  color: white;
}

.line {
  margin-top: -5%;
}
.line-time {
  display: flex;
}

@media (max-width: 768px) {
  .nuestra-historia {
    padding: 2em;
}
.content-subtitle {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  margin-top: 1em;
}
.bubble-line {
  width: 0em;
  height: 0em;
}
.line-time {
  display: none;
}
.content-popups {
  margin-top: 3em;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
}
.content-text-history {
  padding: 3%;
  border-radius: 2em;
  background-color: #EBF1F5;
  height: auto;
  margin-left: 5%;
  margin-right: 5%;
  text-align: center;
  color: #004E7D;
  font-weight: 500;
}
.my-time-item-selected {
  width: 4em;
  height: 3em;
  border-radius: 10em;
  background-color: #172C49;
  color: white;
  padding: 4% 2%;
  text-align: center;
  font-weight: 700;
  font-size: large;
}
.my-time-item {
  width: 3em;
  height: 1.5em;
  border-radius: 10em;
  background-color: #EBF1F5;
  border: 5px solid #172C49;
  cursor: pointer;
}
.btn-move {
  background-color: #EBF1F5;
  border-radius: 10em;
  border: 0;
  height: 1.5em;
  width: 2.5em;
}
@media (max-width: 767px) {
        .d-flex {
            width: 15em;
        }
    }

    .line {
  margin-top: -25%;
}
.my-time-item-selected {
  width: 3em;
  height: 3em;
  border-radius: 10em;
  background-color: #172C49;
  color: white;
  padding: 4% 2%;
  text-align: center;
  font-weight: 700;
  font-size: large;
}
.my-time-item {
  width: 1.5em;
  height: 1.5em;
  border-radius: 10em;
  background-color: #EBF1F5;
  border: 5px solid #172C49;
  cursor: pointer;
}
}
  </style>
  