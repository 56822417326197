// es.js
export default {
    message: {
      nuestros_servicios: 'Nuestros Servicios',
      quienes_somos: '¿Quiénes somos?',
      contactanos: "¡Contáctanos!",
      somos_tecnologia: '¡Somos Tecnología al servicio de la logística!',
      nuestras_soluciones: 'Nuestras soluciones',
      soluciones_adicionales: 'Soluciones Adicionales',
      nuestro_compromiso: 'Nuestro Compromiso',
      aliados: 'Aliados Estratégicos',
      que_nos_diferencia: '¿Qué nos diferencia?',
      nuestra_historia: 'Nuestra Historia',
      nuestro_enfoque: 'Nuestro Enfoque',
      nuestros_principios: 'Nuestros Principios',
      politicas: 'Políticas de Privacidad - ',
      terminos: 'Términos y Condiciones',
      tecnologia: 'Tecnología',
      aereo: 'Aéreo',
      maritimo: 'Marítimo',
      terrestre: 'Terrestre',
      porth_app: 'Porth.app:',
      porth_description: 'Es una de nuestras inversiones estratégicas de tecnología para visualizar en tiempo real los embarques.',
      tive: 'Tive:',
      tive_description: 'Rastreador en tiempo real que mide temperartura, iluminación, golpes, humedad y ubicación.',
      aurora: 'Aurora: ',
      aurora_description: 'Administración de RPAs y corizador en línea.',
      aurelio: 'Aurelio: ',
      aurelio_description: 'Asistente virtual y gestión de embarques.',
      linc: 'Linc 3.0: ',
      linc_description: 'Plataforma logística digital. Cotizador global en línea.',
      servicio_puerta_puerta: 'Servicios puerta a puerta desde y hacia cualquier parte del mundo.',
      chatear_parciales: 'Charter parciales y completos.',
      reservacion_espacios: 'Reservación de espacios con líneas aéreas.',
      recepcion_manejo: 'Recepción y manejo de cargas en el Aeropuerto.',
      preparacion_tramite: 'Preparación y trámite de documentos.',
      recolecta: 'Recolectas en cualquier parte del mundo.',
      importacion_exportacion: 'Importación y exportación.',
      seguro_carga: 'Seguro de carga Full Cover y 0% Deducible.',
      coordinacion_equipos: 'Coordinación de equipos completos desde y hacia cualquier parte del mundo.',
      consolidado_semanal: 'Consolidado Semanal desde Miami, FL a Centroamérica y Panamá',
      carga_sobredimensionada: 'Carga sobredimensionada y proyectos especiales.',
      servicio_puerta: 'Servicios puerta a puerta.',
      red_transporte: 'Red de transporte Centroamericano – México.',
      coordinacion_furgones: 'Coordinación Furgones Completos desde y hacia Centroamérica, Panamá y México.',
      consolidado_semanal_terrestre: 'Consolidado Semanal',
      bodegas_consolidacion: 'Bodegas de consolidación en México, Panamá y Costa Rica.',
      recolectas_terrestre: 'Recolectas en cualquier parte de la Región Centroamericana y México.',
      contiza_embarques: 'Cotiza tus embarques',
      cotiza_cargas: 'Cotiza tu carga',
      cotiza1: 'Cotiza tus embarques ',
      cotiza2: 'Aéreos ',
      cotiza3: ' y ',
      cotiza4: ' Marítimos ',
      cotiza5: 'en línea',
      des1: 'Transparencia de tarifas',
      des2: 'Facilidad para reservar',
      des3: 'Tarifas instantáneas',
      des4: 'Gestor de cuenta personal',
      des5: 'Plataforma colaborativa',
      des6: 'Visibilidad en tiempo real',
      des7: 'No necesitas registrarte.',
      des8: 'Cotización en línea al instante.',
      des9: 'Tipos de transporte FTL y LTL.',
      des10: 'Puedes asegurar tus cargas.',
      des11: 'Precios transparentes.',
      cotiza1_terrestre: 'Cotiza tus cargas',
      terrestres: 'Terrestres',
      seguro_de_carga: 'Seguro de Carga',
      cargas_especiales: 'Cargas Especiales',
      servicio_custodio: 'Servicio Custodio',
      tramite_aduanal: 'Trámite Aduanal',
      habla_con_asesor: 'Habla con un asesor',
      nuestro_compromiso_description: 'Grupo Linc está comprometido con la mejora continua en el desempeño de nuestro Sistema de Gestión, con el propósito de satisfacer los requerimientos de nuestros clientes que buscan un servicio de transporte local e internacional. Queremos ser un aliado oportuno, confiable y seguro durante el tiempo en que la mercancía esté bajo nuestra responsabilidad.',
      nos_hace_especiales: '¿Qué nos hace especiales?',
      somos_regionales: 'Somos Regionales en Centroamérica con alcance Global',
      servicio_en_tecnologia: 'Servicios con Tecnología de Rastreo y Gestión en Tiempo Real',
      tranparencia_tarifas: 'Transparencia de tarifas con nuestro Cotizador en Línea',
      seguridad_calidad: 'Seguridad y Calidad respaldadas con Certificaciones',
      especial_description: 'Conversa con nuestros profesionales expertos en logística, accede a una solución personalizada para tu empresa y experimenta una demostración en vivo de nuestra solución.',
      queremos_tranformar: '¡Queremos transformar digitalmente la Industria del Transporte Internacional generando empleo y bienestar!',
      grupo_linc: 'Grupo Linc',
      quienes_somos_description1: 'Somos un grupo de más de 40 colaboradores enfocados en servicios de transporte internacional aéreo y marítimo, además hemos desarrollado nuestra propia red de transporte Centroamericano por carretera desde México hasta Panamá y desde Zona Libre y San José hasta Centroamérica.',
      quienes_somos_description2: 'Grupo Linc, cuenta con un robusto equipo de profesionales enfocados en diseñar soluciones para nuestros clientes.',
      quienes_somos_description3: 'También manejamos carga de gran tamaño / sobrepeso, mercancías peligrosas, comercio cruzado, carga de proyectos, además, hemos desarrollado nuestra red de transporte por carretera desde Costa Rica a ciudades de América Central y Panamá.',
      quienes_somos_description4: 'Linc Costa Rica, cuenta con un Sistema de Gestión de Seguridad y Control desde el 2016 y fue certificado en 2017 y con un Sistema de Gestión de Calidad certificado en 2019.',
      trayectoria: '+11 años de trayectoria y evolución en la industria logística',
      mision: 'Misión',
      vision: 'Visión',
      mision_description1: '“En Grupo Linc, las personas son lo más importante. Nos especializamos en logística y transporte internacional, brindando soluciones personalizadas, tecnológicas y eficientes. ',
      mision_description2: 'Nuestro enfoque se basa en la transparencia de la información, la calidad y la seguridad, garantizando servicios de excelencia para nuestros clientes.”',
      vision_description: '“Ser una empresa rentable, líder en logística y transporte internacional, con presencia multilatina y alcance global, garantizando soluciones dinámicas al mercado cambiante, a través de la implementación estratégica de tecnología, la calidez humana, mejora continua y comercio seguro.”',
      fe: 'Fe en Dios',
      description_fe: 'Certeza de lo que se espera, la convicción de lo que no se ve.',
      pasion: 'Pasión',
      description_pasion: 'Amor y dedicación por lo que hacemos',
      honestidad: 'Honestidad',
      description_honestidad: 'Veracidad de la palabra.',
      integridad: 'Integridad',
      description_integridad: 'Actuar conforme a la ética y la moral',
      compromiso: 'Compromiso',
      description_compromiso: 'Cumplir con lo pactado. ',
      liderazgo: 'Liderazgo',
      description_liderazgo: 'Potencializar las habilidades blandas y duras de nuestros colaboradores, enseñando, acompañando y confiando.',
      podemos_ayudarte: '¿Cómo podemos ayudarte?',
      podemos_ayudarte_description: 'Ya sea que tengas una pregunta sobre soluciones, alcance, tarifas, o cualquier consulta, nuestro equipo está listo para responder todas sus preguntas.',
      description_2017: 'Se apertura oficinas en Guatemala. Ingresamos a la red de agentes WCA World. Y nos certificamos BASC.',
      description_2012: 'Iniciamos operaciones en Costa Rica', 
      description_2013: 'Se establecen oficinas en Nicaragua y Panamá (Ciudad y Zona Libre).', 
      description_2019: 'Nos unimos a Twig Network', 
      description_2020: 'Nos certificamos ISO:9001. Inicio de alianza con Parnity.', 
      description_2021: 'Iniciamos Porth.app junto a Gustavo Daniel', 
      description_2022: 'Lanzamos aureliobot Director de Princing Virtual.', 
      description_2023: 'Creamos auroraboot el Cotizador Terrestre. Lanzamos Linc 3.0. Y nos unimos a Conqueror.', 
      nombre: 'Nombre Completo',
      correo: 'Correo',
      pais: 'País',
      telefono: 'Teléfono',
      empresa: 'Empresa',
      servicio: 'Servicio',
      solicitud: 'Solicitud',
      ayuda: 'Ayuda',
      blog: 'Blog',
      transporte_terrestre: 'Transporte Terrestre',
      transporte_maritimo: 'Transporte Marítimo',
      transporte_aereo: 'Transporte Aéreo',
      torre_control: 'Torre de Control de Embarques',
      enviar: 'Enviar'
    },
  };
  