<template>
    <div class="nuestro-enfoque">
        <h3 style="color: #004E7D; font-weight: 700;">{{ $t('message.nuestro_enfoque') }}</h3>
        <div class="row gx-5" style="padding: 2em; place-content: center;">
            <div class="col-md-5 col-12">
            <div class="content-compromiso">
                <img src="../assets/icon-mision.png" class="icon-compromiso" style="width: 20%; margin-bottom: 0.5em;" alt="">
                <h3 style="color: #E52037; margin-bottom: 1em;">{{ $t('message.mision') }}</h3>
                <h7 style="color: #004E7D;">{{ $t('message.mision_description1') }}</h7><br><br>
                <h7 style="color: #004E7D;">{{ $t('message.mision_description2') }}</h7>
            </div>
            </div>
            <div class="col-md-5 col-12">
            <div class="content-compromiso">
                <img src="../assets/icon-vision.png" class="icon-compromiso" alt="">
                <h3 style="color: #E52037;  margin-bottom: 1em;">{{ $t('message.vision') }}</h3>
                <h7 style="color: #004E7D;">{{ $t('message.vision_description') }}</h7>
            </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.nuestro-enfoque {
    background-color: #EBF1F5;
    padding: 5em;
}
.icon-compromiso {
    width: 25%;
    margin-bottom: 1em;
}
.content-compromiso {
    background: white;
    border-radius: 3em;
    box-shadow: 0px 4px 6px rgba(0.5, 0.5, 0.5, 0.5);
    height: 65vh;
    padding: 2em;
    text-align: center;
}

@media (max-width: 767px) {

    .nuestro-enfoque {
        background-color: #EBF1F5;
        padding: 2em;
    }
  .gx-5 > .col-6 {
    padding: 0;
    width: 100%;
  }

  .gx-5 > .col-6 > .content-compromiso {
    text-align: center;
  }

  .icon-compromiso {
    width: 40%;
  }

  .content-compromiso {
    padding: 2em;
    height: auto;
    border-radius: 3em;
    margin-bottom: 1em;
  }

  .content-compromiso h3 {
    font-size: 18px;
  }

  .content-compromiso h7 {
    font-size: 14px;
  }

  .content-compromiso h3 {
    margin-bottom: 0.5em;
  }
}
  </style>
  