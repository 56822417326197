<template>
    <div class="nuestros-principios">
        <h3 style="color: white;">{{ $t('message.nuestros_principios') }}</h3>
        <div class="row row-cols-2 row-cols-lg-3 g-2 g-lg-3" style="padding: 2em;">
            <div class="col text-center text-description">
                <img src="../assets/icon-fe.png" class="icon-soluciones" alt="...">
                <h5 style="color: white;">{{ $t('message.fe') }}</h5>
                <h7 style="color: white;">{{ $t('message.description_fe') }}</h7>
            </div>
            <div class="col text-center text-description">
                <img src="../assets/icon-pasion.png" class="icon-soluciones" alt="...">
                <h5 style="color: white;">{{ $t('message.pasion') }}</h5>
                <h7 style="color: white;">{{ $t('message.description_pasion') }}</h7>
            </div>
            <div class="col text-center text-description">
                <img src="../assets/icon-honestidad.png" class="icon-soluciones" alt="...">
                <h5 style="color: white;">{{ $t('message.honestidad') }}</h5>
                <h7 style="color: white;">{{ $t('message.description_honestidad') }}</h7>
            </div>
            <div class="col text-center text-description">
                <img src="../assets/icon-integridad.png" class="icon-soluciones" alt="...">
                <h5 style="color: white;">{{ $t('message.integridad') }}</h5>
                <h7 style="color: white;">{{ $t('message.description_integridad') }}</h7>
            </div>
            <div class="col text-center text-description">
                <img src="../assets/icon-compromiso.png" class="icon-soluciones" alt="...">
                <h5 style="color: white;">{{ $t('message.compromiso') }}</h5>
                <h7 style="color: white;">{{ $t('message.description_compromiso') }}</h7>
            </div>
            <div class="col text-center text-description">
                <img src="../assets/icon-liderazgo.png" class="icon-soluciones" alt="...">
                <h5 style="color: white;">{{ $t('message.liderazgo') }}</h5>
                <h7 style="color: white;">{{ $t('message.description_liderazgo') }}</h7>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.nuestros-principios {
    background-color: #004E7D;
    padding: 3em;
}
.content-items {
    background: #E6EDF2;
    height: 100%;
    border-radius: 3em;
}
.icon-soluciones {
    width: 20%;
    margin-bottom: 1em;
}
.btn-soluciones {
    background-color: white;
    color: #172C49;
    border-radius: 5em;
    width: inherit;
    font-weight: 600;
}

.text-description {
    padding: 2em 5em;
}

@media (max-width: 767px) {
    .nuestros-principios {
        background-color: #004E7D;
        padding: 2em;
    }
    .text-description {
        padding: 0;
        width: 100%;
    }

    .row-cols-2,
  .row-cols-lg-3 {
    grid-template-columns: 1fr;
  }

  /* Ajustar el espaciado entre los elementos en dispositivos móviles */
  .g-2,
  .g-lg-3 {
    gap: 1rem;
  }
}
  </style>
  