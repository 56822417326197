import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import '@/assets/bootstrap.min.css'
import '@/assets/bootstrap.bundle.min.js'
import { createI18n } from 'vue-i18n'
import en from '@/locales/en'
import es from '@/locales/es'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faPhoneAlt, faChevronRight, faChevronLeft, faArrowUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import VueGtag from 'vue-gtag'
import axios from 'axios'
import VueAxios from 'vue-axios'
import cors from 'cors'



library.add(faFacebook, faInstagram, faLinkedin, faMapMarkerAlt, faPhoneAlt, faEnvelope, faChevronRight, faChevronLeft, faArrowUp, faChevronDown )

const i18n = createI18n({
    legacy: false,
    locale: 'es', // Idioma predeterminado
    messages: {
      en,
      es,
    },
});

const app = createApp(App)
// Configuration VueAnalytics
app.use(VueGtag, {
  config: {
      id: 'G-83PDXYW6SN',
      params: {
          send_page_view: false
      }
  }
}, router)
app.use(VueAxios, axios)
app.use(cors)
app.use(i18n)
app.use(router)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')


