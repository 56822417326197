<template>
    <div class="nuestro-compromiso">
        <h2 style="color: #172C49; text-align: center; font-weight: 700;">{{ $t('message.nos_hace_especiales') }}</h2>
        <div class="row">
            <div class="row my-5 mx-3">
            <div class="col text-center mx-4">
                <img src="../assets/icon-world.png" class="icon-diferencias" alt="...">
                    <h5 style="color: #004E7D;">{{ $t('message.somos_regionales') }}</h5>
            </div>
            <div class="col text-center mx-4">
                <img src="../assets/icon-ubication.png" class="icon-diferencias" style="width: 20%;" alt="...">
                <h5 style="color: #004E7D;">{{ $t('message.servicio_en_tecnologia') }}</h5>
            </div>
            <div class="col text-center mx-4">
                <img src="../assets/icon-line-world.png" class="icon-diferencias" alt="...">
                <h5 style="color: #004E7D;">{{ $t('message.tranparencia_tarifas') }}</h5>
            </div>
            <div class="col text-center mx-4">
                <img src="../assets/icon-1.png" class="icon-diferencias" style="width: 23%; margin-bottom: 0.8em;" alt="...">
                <h5 style="color: #004E7D;">{{ $t('message.seguridad_calidad') }}</h5>
            </div>
        </div>
</div>

    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.nuestro-compromiso {
    background-color: white;
    padding: 3em;
    padding-top: 6em;
    padding-bottom: 1.5em;
}
.icon-diferencias {
    width: 30%;
    color: #004E7D;
    margin-bottom: 1em;
}
.text-especiales {
    color: #004E7D;
    margin-left: 5em;
    margin-bottom: 4em;
}
.icon-soluciones {
    width: 20%;
    margin-bottom: 1em;
}

.btn-bottom {
    border-radius: 2em;
    background-color: #004E7D;
    color: white;
    width: 60%;
    height: 10%;
    font-size: x-large;
  }

  @media (max-width: 768px) {
    .nuestro-compromiso {
    background-color: #EBF1F5;
    padding: 3em 1em;
    }
    .text-especiales {
    color: #004E7D;
    margin-left: 1em;
    margin-bottom: 3em;
    }


}
  </style>
  