import { createRouter, createWebHistory } from 'vue-router'
import NuestrosServicios from './views/NuestrosServicios.vue'
import QuienesSomos from './views/QuienesSomos'
import ContactanosLinc from './views/ContactanosLinc'
import ContactanosIcontainers from './views/ContactanosIcontainers'

const routes = [
  { 
    path: '/',
    redirect: 'nuestros-servicios'
  },
    {
      path: '/nuestros-servicios',
      name: 'NuestrosServicios',
      component: NuestrosServicios
    },
    {
      path: '/quienes-somos',
      name: 'QuienesSomos',
      component: QuienesSomos,
      props: true
    },
    {
      path: '/contactanos',
      name: 'ContactanosLinc',
      component: ContactanosLinc,
      props: true
    }
    ,
    {
      path: '/cotizacion',
      name: 'ContactanosIcontainers',
      component: ContactanosIcontainers,
      props: true
    }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
})

export default router
