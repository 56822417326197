<template>
    <div class="nuestras-soluciones">
        <h2 class="heading">{{ $t('message.nuestras_soluciones') }}</h2>
        <div class="container mx-4">
            <div class="row row-cols-1 row-cols-md-2 g-2 g-md-3 mx-2 mx-md-5">
                <div class="col px-4">
                  <div class=" px-5 py-5 border content-items">
                    <div class="d-flex gap-3 align-items-center">
                      <img src="../assets/icon-robot.png" style="width: 20%;" alt="...">
                      <h4 style="margin-bottom: 0%; color: #E52037;">{{ $t('message.tecnologia') }}</h4>
                    </div>
                    <ul class="list-group my-list-group">
                        <li style="margin-top: 1em;"><h8 class="size-mobile" style="color: #004E7D; font-weight: 600;">{{ $t('message.porth_app') }}</h8> <h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.porth_description') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D; font-weight: 600;">{{ $t('message.tive') }}</h8> <h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.tive_description') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D; font-weight: 600;">{{ $t('message.aurora') }}</h8> <h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.aurora_description') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D; font-weight: 600;">{{ $t('message.aurelio') }}</h8> <h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.aurelio_description') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D; font-weight: 600;">{{ $t('message.linc') }}</h8> <h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.linc_description') }}</h8></li><br>
                      </ul>
                      <div>
                        <a href="/contactanos" class="btn btn-bottom" type="button">{{ $t('message.contactanos') }}</a>
                      </div>
                  </div>
                </div>
                <div class="col px-4">
                  <div class="px-5 py-5 border content-items">
                    <div class="d-flex gap-3 align-items-center">
                      <img src="../assets/icon-plane.png" style="width: 17%;" alt="...">
                      <h4 style="margin-bottom: 0%; color: #E52037;">{{ $t('message.aereo') }}</h4>
                    </div>
                    <ul class="list-group my-list-group">
                        <li style="margin-top: 1em;"><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.servicio_puerta_puerta') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.chatear_parciales') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.reservacion_espacios') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.recepcion_manejo') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.preparacion_tramite') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.recolecta') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.importacion_exportacion') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.seguro_carga') }}</h8></li><br><br><br>
                      </ul>
                      <div>
                        <a class="btn btn-bottom" type="button" href="https://linc.icontainers.com/sign-in" target="_blank">{{ $t('message.cotiza1') }}</a>
                      </div>
                  </div>
                </div>
                <div class="col px-4">
                  <div class="px-5 py-5 border content-items">
                    <div class="d-flex gap-3 align-items-center">
                      <img src="../assets/icon-maritimo.png" style="width: 23%;" alt="...">
                      <h4 style="margin-bottom: 0%; color: #E52037;">{{ $t('message.maritimo') }}</h4>
                    </div>
                    <ul class="list-group my-list-group">
                        <li style="margin-top: 1em;"><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.coordinacion_equipos') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.consolidado_semanal') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.carga_sobredimensionada') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.preparacion_tramite') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.servicio_puerta') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.recolecta') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.importacion_exportacion') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.seguro_carga') }}</h8></li><br>
                      </ul>
                      <div>
                        <a class="btn btn-bottom" type="button" href="https://linc.icontainers.com/sign-in" target="_blank">{{ $t('message.cotiza1') }}</a>
                      </div>
                  </div>
                </div>
                <div class="col px-4">
                  <div class=" px-5 py-5 border content-items">
                    <div class="d-flex gap-3 align-items-center">
                      <img src="../assets/icon-terrestre.png" style="width: 20%;" alt="...">
                      <h4 style="margin-bottom: 0%; color: #E52037;">{{ $t('message.terrestre') }}</h4>
                    </div>
                    <ul class="list-group my-list-group">
                        <li style="margin-top: 1em;"><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.red_transporte') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.coordinacion_furgones') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.consolidado_semanal_terrestre') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.bodegas_consolidacion') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.servicio_puerta') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.recolectas_terrestre') }}</h8></li>
                        <li><h8 class="size-mobile" style="color: #004E7D;">{{ $t('message.seguro_carga') }}</h8></li><br>
                      </ul>
                      <div>
                        <a class="btn btn-bottom" type="button"  href="https://aurorabot.linc-ca.com/" target="_blank">{{ $t('message.cotiza_cargas') }}</a>
                      </div>
                  </div>
            </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  }
  }
  </script>
  
  <style scoped>
.nuestras-soluciones {
    padding: 3em 5em;
}
.content-items {
    background: #E6EDF2;
    height: auto;
    border-radius: 3em;
    padding: 2.5rem;
}
.heading {
  margin-bottom: 5%;
  font-weight: 700;
}
.btn-bottom {
    border-radius: 2em;
    background-color: #004E7D;
    color: white;
    height: 10%;
    font-size: x-large;
    width: auto;
    padding: 0.2em 1em;
  }

  .btn-bottom:hover {
    background-color: #A6C2E1;
  }
  
.my-list-group {
  margin-top: 1em;
}


  @media (max-width: 768px) {
  .nuestras-soluciones {
    padding: 3em 1em;
}
.content-items {
    background: #E6EDF2;
    height: 100vh;
    border-radius: 3em;
    padding: 2rem;
}
.row {
    flex-direction: column; /* Cambiar la dirección de la flexbox a columna */
  }
  
  .row-cols-2 > .col {
    width: 50%; /* Cada columna ocupa el 50% del ancho en dispositivos móviles */
  }
  
  .row-cols-lg-2 > .col {
    width: 100%; /* Cada columna ocupa el 100% del ancho en dispositivos móviles */
  }
  .my-list-group {
  margin-top: 1em;
  height: 80%;
} 
.size-mobile {
  font-size: small;
}
.btn-bottom{
  width: 100%;
}
}
@media (max-width: 768px) {
  .nuestras-soluciones {
    padding: 3em 1em;
  }

  .content-items {
    height: auto;
    padding: 1.5rem;
  }

  .row {
    flex-direction: column;
  }

  .row-cols-1 > .col {
    width: 100%;
  }

  .my-list-group {
    height: auto;
    max-height: 50vh;
    overflow-y: auto;
  }

  .heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .btn-bottom {
    width: 100%;
  }

  .size-mobile {
    font-size: small;
  }
}
  </style>
  