<template>
    <div class="imagen-equipo">
        <h2 class="title-section">{{ $t('message.quienes_somos') }}</h2>
        <h4 class="px-3 subtitle-quienes-somos">{{ $t('message.grupo_linc') }}</h4>
        <div class="row g-2">
            <div class="col-6">
            <div class="p-3 text-quienes-somos">{{ $t('message.quienes_somos_description1') }}</div>
            <div class="p-3 text-quienes-somos">{{ $t('message.quienes_somos_description2') }}</div>
            </div>
            <div class="col-6">
            <div class="p-3 text-quienes-somos">{{ $t('message.quienes_somos_description3') }}</div>
            <div class="p-3 text-quienes-somos">{{ $t('message.quienes_somos_description4') }}</div>
            </div>
        </div>

    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  export default {
    setup() {
    const { t } = useI18n()

    return {
        t
    }
    
  }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.imagen-equipo {
    padding: 4em;
    text-align: center;
    text-align: -webkit-center;
}
.img-grupo-linc {
    width: 100vh;
    border-radius: 5em;
}
.subtitle-quienes-somos {
    color: #172C49;
    text-align: left;
}
.text-quienes-somos {
    text-align: justify;
    color: #004E7D;
    font-weight: 500;
}
.title-section {
    color: #004E7D;
    width: 50%;
    font-weight: 700;
}

@media (max-width: 768px) {
  .imagen-equipo {
    padding: 1em;
    text-align: center;
    text-align: -webkit-center;
}
.text-quienes-somos {
    text-align: justify;
    color: #004E7D;
    font-weight: 500;
    font-size: small;
}
}
  </style>
  