<template>
  <div class="contactanos-historia">
    <div class="row row-cols-1 row-cols-md-2">
      <div class="col-md" style="align-self: center;">
        <h3 style="color: #172C49; margin-bottom: 1em;">{{ $t('message.podemos_ayudarte') }}</h3>
        <p style="color: #004E7D; width: 90%; margin-top: 1em;">{{ $t('message.podemos_ayudarte_description') }}</p>
        <div class="d-grid gap-2 col-8 col-md-6 col-lg-4" style="margin-top: 2em;">
          <a class="btn-bottom-contactanos" type="button" href="/contactanos">{{ $t('message.contactanos') }}</a>
        </div>
      </div>
      <div class="col-md content-image" style="text-align: center;">
        <img src="../assets/contactanos-image.png" style="width: 75%;" alt="...">
      </div>
    </div>
  </div>
</template>

<style scoped>
.contactanos-historia {
  background-color: #ebf1f5;
  padding: 3em 5em;
}

.btn-bottom-contactanos {
  border-radius: 2em;
  background-color: #004e7d;
  color: white;
  width: 100%;
  text-align: center;
  font-size: x-large;
  padding: 0.5em;
}


@media (max-width: 768px) {
  .contactanos-historia {
    padding: 2em;
  }
  .content-image {
    margin-top: 1em;
  }
  .btn-bottom-contactanos {
    width: 100%;
    font-size: large;
    padding: 0.3em 1.5em;
  }
  .content-image {
    margin-top: 1em;
  }
}
</style>
